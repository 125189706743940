import Vue from 'vue'

export const schema = {
	state: {},
	mutations: {
		schemaSet(state, { property = '', value = null }) {
			if (property) Vue.set(state, property, value)
			else state.data = value
		},
	},
	actions: {
		schemaSet(context, payload) {
			const {commit} = context
			commit('schemaSet', payload)
		},
	},
	getters: {},
}
