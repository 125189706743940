import functions from '@/js/managers/functions'
import store from '@/store/store'

export const manageRoute = function({ _params = {}, _func = {} }) {
	const params = Object.assign({
		home: 'home',
		login: 'login',
		signup: 'signup',
	}, { ..._params })
	const beforeEach = async function beforeEach({ name:to }, from, next) {
		const { login, signup } = params
		if (to === login || to === signup) return next()
		let {
			getters: {
				manageUser: {
					func: {
						isUserValid
					} = {}
				} = {},
				manageForms: {
					params: {
						redirect: {
							onboarding:redirect = {}
						}
					} = {}
				} = {},
				accessToken = null
			} = {}
		} = store || {}
		if (!accessToken) {
			if (!await isUserValid()) {
				name = { login, signup }[to] || login
				return next({ name })
			}
			await store.dispatch('get_current_user')
		}
		const { getters: { user: { logged_user: { onboarding = null } = {} } = {} } = {} } = store
		let name = redirect[onboarding]
		if (name) {
			if (name === to) return next()
			return next({ name })
		}
		next()
	}
	return {
		params,
		func: {
			...functions,
			..._func,
			beforeEach,
		}
	}
}
