import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import router from '@/router'
import { mutations as mutationFunctions } from '../store-functions'
import { messages } from '@/js/constants/messagesEN'
import { getValueOnPath } from '@/js/utils/utils'
import { purify } from '@/js/utils/utils'
import { manageSignUp } from '@/js/managers/manageSignUp'
import { manageUser } from '@/js/managers/manageUser'

// === STATE =====================================================================================
const state = {
	logged_user: false,
	loggedIn: false,
	'practice_fields': Object.keys(messages['practice_fields']),
	signup_success: false,
	manageForms: new manageSignUp({
		_params: {
			createAnonymous: {
				query: 'CLIENT_GAME_BY',
				variables: { tags: 'signUp' }
			},
			createProfile: {
				query: 'CLIENT_GAME_BY',
				variables: { tags: 'createProfile' }
			},
			userStates: {
				is_anonymous: {
					mutation: 'CREATE_PROGRESSION',
					route: 'signUp'
				},
				is_confirmed: {
					mutation: 'CREATE_PROGRESSION',
					route: 'signUp'
				}
			},
			redirect: {
				onboarding: {
					is_anonymous: 'signup',
					is_confirmed: 'signup',
				}
			}
		}
	}),
	manageUser: new manageUser({
		_params: {
			queries: {
				currentUser: 'CURRENT_USER',
			},
		},
	}),
}

// === GETTERS =====================================================================================
const getters = {
	user(state) { return state },
	logged_user(state) { return state.logged_user },
	relationships: state => getValueOnPath(state.logged_user, 'relationships.data'),
	manageUser: state => state.manageUser,
	manageForms: state => state.manageForms,
	progressionId: state => state.manageForms.progressionId,
	formStep: state => state.manageForms.formStep,
}

// === ACTIONS =====================================================================================
const actions = {
	async get_practice_fields({ commit }) {
		commit('SET_PRACTICE_FIELDS', await Apollo.query('CONTENT_FORM_SELECT_INPUT', { id: '5efb60dbc327c600060ad75c' }))
	},
	clearSignUpForm({ commit }) {
		commit('clearSignUpForm')
	},
	setFormStep({ commit }, { step }) {
		commit('setFormStep', { step })
	},
	relationships({ commit }, { relationships }) {
		commit('relationships', { relationships })
	},
	async deleteRelationship({ commit, dispatch }, { requesterId, requesteeId }) {
		if (!requesterId || !requesteeId) return
		await commit('deleteRelationship', { requesterId, requesteeId })
	},
	async sendColleagueRequest({ commit, dispatch }, { id }) {
		if (!id) return
		await commit('sendColleagueRequest', { id })
	},
	setUserProperty({ commit, dispatch }, payload) {
		commit('setUserProperty', payload)
	},
	async get_current_user({ commit, dispatch }) {
		const result = await purify(Apollo.query('CURRENT_USER'))
		if (!result) return false
		const { profile: { practice_field: { id:value = null, option_id } = {} } = {} } = result
		if (option_id) {
			const {html} = await Apollo.query('CONTENT_TEXT', {id: option_id})
			if (html) Object.assign(result.profile, {practiceFieldName: html})
		}
		commit('setUser')
		commit('EDIT_USER_STATE', { logged_user: result })
		if (value) await dispatch('set_game_default_filters', { property: 'practice_field_include', value })
		return result
	},
	setUser({ commit }, payload) {
		commit('setUser', payload)
	},
	removeUser({ commit }) {
		commit('removeUser')
	},
	async reset_progression({ commit }) {
		const result = await Apollo.mutate('RESET_USER')
		if (result) {
			commit('EDIT_USER_STATE', { logged_user: result })
		}
	},

	logout({ commit, dispatch }) {
		dispatch('removeUser')
		dispatch('removeAccessToken')
		commit('EDIT_USER_STATE', { logged_user: false })
		router.push({ name: 'login' })
	}
}

// === MUTATIONS =====================================================================================
const mutations = {
	...mutationFunctions,
	SET_PRACTICE_FIELDS(state, { selectableElements }) {
		state.practice_fields = selectableElements.map(x => {
			const { id = null, option: { html = '' } = {} } = x
			return {
				html,
				id,
			}
		})
	},
	clearSignUpForm(state) {
		delete state.manageForms.step
	},
	setFormStep(state, { step }) {
		Vue.set(state.manageForms, 'step', step)
	},
	progressionId(state, { progressionId }) {
		Vue.set(state.manageForms, 'progressionId', progressionId)
	},
	relationships(state, { relationships }) {
		const friends = relationships.accepted.map(x => x.requester)
		Vue.set(state.logged_user, 'friends', friends)
		Vue.set(state.logged_user, 'relationships', [ ...relationships.pending, ...relationships.refused ])
	},
	async deleteRelationship(state, { requesterId, requesteeId }) {
		const input = { requesterId, requesteeId }
		await Apollo.mutate('DELETE_RELATIONSHIP', { input })
	},
	async sendColleagueRequest(state, { id: requesteeId }) {
		await Apollo.mutate('CREATE_RELATIONSHIP', { requesteeId })
	},
	EDIT_USER_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},
	setUser(state) {
		Vue.set(state, 'loggedIn', true)
	},
	removeUser(state) {
		Vue.set(state, 'logged_user', false)
		Vue.set(state, 'loggedIn', false)
		Vue.set(state, 'signup_success', false)
	},
	setUserProperty(state, { property, value }) {
		if (!property) return
		else Vue.set(state.logged_user, property, value)
	}
}

export default { state, getters, actions, mutations }
