import Vue from 'vue'
import store from './store'

export const mutations = {
	set(state, { property = '', value = null }) {
		if (property) Vue.set(state, property, value)
		else state.data = value
	},
}

export const actions = {
	set(context, payload) {
		const {commit} = context
		commit('set', payload)
	},
}
