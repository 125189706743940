const messages = {
	'Féminin': 'woman',
	'Masculin': 'man',
	loading_images: 'Chargement des médias... ',
	reset: 'Réinitialisation',
	to: `Jusqu'à`,
	from: 'À partir de',
	back: 'Retour',
	home: 'Accueil',
	login: 'identification',
	signup: 'Inscription',
	'create-account': 'Créer un compte',
	email: 'courriel',
	'e-mail': 'courriel',
	password: 'mot de passe',
	passwordConfirmation: 'Confirmation du mot de passe',
	firstName: 'Prénom',
	lastName: 'Nom',
	familyName: 'Nom de famille',
	clinicName: 'Nom de la clinique',
	save: 'sauvegarder',
	yes: 'oui',
	No: 'non',
	next: 'suivant',
	confirm: 'Confirmer',
	submit: 'Valider',
	deny: 'Annuler',
	username: 'Identifiant',
	'practice-name': 'Nom de la clinique',
	'add one more': 'Ajoutez en un',
	forgot_password: `J'ai oublié mon mot de passe`,
	'Password reset': 'Réinitialiser le mot de passe',
	not_supported_screen_size: `Veuillez changer l'orientation de votre appareil ou effectuer un zoom arrière.`,
	practice_field: 'Champ de pratique',
	'practice_fields': {
		'general-practitioner': 'Médecin généraliste',
		family_medicine: 'Médecin de famille',
		rheumatologist: 'Rhumatologue',
		neurologist: 'Neurologue',
		dermatologist: 'Dermatologue',
		cardiologist: 'Cardiologue',
		nurse: 'Infirmière',
		pharmacist: 'Pharmacien(ne)',
		other: 'Autre',
	},
	'vee-validate': {
		news_emails: {
			required: ''
		},
		practice_field: {
			required: 'Veuillez choisir votre domaine',
		},
		terms_and_conditions: {
			required: 'Veuillez lire les termes et conditions',
		},
		hcp: {
			required: 'Veuillez certifier que vous êtes un HCP',
		},
		email: {
			required: 'Veuillez inscrire votre courriel',
			invalid: 'Veuillez inscrire un courriel valide'
		},
		firstName: {
			required: 'Le prénom est requis',
			'length-big': 'Le prénom est limité à {max} lettres',
			alpha: 'Le prénom ne doit contenir que des lettres'
		},
		lastName: {
			required: 'Le nom est requis',
			'length-big': 'Le nom est limité à {max} lettres',
			alpha: 'Le nom ne doit contenir que des lettres'
		},
		userName: {
			'length-big': `L'identifiant est limité à 15 caractères`,
			alpha_num: `L'identifiant ne peut contenir que des lettres et chiffres`,
			required: 'Veuillez inscrire votre identifiant',
			invalid: 'Veuillez inscrire un identifiant valide'
		},
		password: {
			required: 'Veuillez inscrire votre mot de passe',
			'length-small': 'Le mot de passe doit contenir, au moins, {min} lettres, chiffres ou symboles.',
			'length-big': 'Le mot de passe doit contenir, au plus, {max} lettres, chiffres ou symboles.',
			confirmed: 'La confirmation et le mot de passe ne correspondent pas',
			invalid: 'Le mot de passe est invalide',
			notStrong: `Doit être d'au moins 8 caractères; 1 lettre minuscule; 1 lettre majuscule; 1 chiffre; certains caractères spéciaux.
			`,
		},
		'practice-name': {
			required: 'Le nom de la clinique est requis',
			'length-big': 'Le nom de la clinique doit contenir, au plus, {max} lettres, ou symboles.',
		},
		'clinicName': {
			'length-big': 'Le nom de la clinique ne peut contenir plus de {max} caractères.'
		},
		'please-select-expertise': {
			required: 'Votre expertise est requise',
		},
		q1: {
			required: 'Veuillez donner votre réponse'
		}
	},
	date_missing: `Date manquante`,
	no_practice_name: 'Nom de clinique manquant',
	practice_field_missing: 'Domaine de pratique manquant',
	user_name_missing: `Nom d'usager manquant`,
	add_colleague: 'Ajouter le collègue',
	remove_colleague: 'Enlever le collègue',
	language: 'Langue',
	see_completed: 'Voir les études complétées',
	restart_game: 'La partie redémarre pour appliquer les changements.',
	ultra_quality: 'Ultra',
	high_quality: 'Haute',
	standard_quality: 'Normale',
	settings: 'Paramètres',
	male: 'Masculin',
	female: 'Féminin',
	archives: 'Archives',
	the_staff_room: 'La salle du personnel',
	invalid_credentials: 'Identification invalide',
	disclaimer: 'Avertissement',
	stamp_for_approval: `Tampon pour<br>approbation`,
	report_issue: 'Il y a un<br>problème',
	reply_to: 'Répondre à',
	start: 'Démarrer',
	share_your_thoughts: 'Partager votre pensée !',
	edit_comment: 'Éditer le commentaire',
	delete_comment: 'Effacer le commentaire',
	delete: 'Effacer',
	reply: 'réponse',
	replies: 'réponses',
	Reply: 'Répondre',
	cancel: 'Annuler',
	comments: 'Commentaires',
	about_your_answer: 'À propos de votre réponse',
	answer: 'Réponse',
	scenario_timeline: 'Chronologie du scénario',
	question: 'Question',
	OK: 'OK',
	comment: 'Commenter',
	recap: 'Récapituler',
	result: 'Résultat',
	select: 'Sélectionner',
	unselect: 'Désélectionner',
	select_your_answer: 'Sélectionnez votre réponse',
	done: 'Débuter',
	summary: 'Sommaire',
	end_of_scenario: 'Fin du scénario',
	next_page: 'Page suivante',
	click_here_to_continue: 'Cliquez ici pour continuer',
	request_denied: 'Requête refusée',
	colleague_added: 'Collègue ajouté',
	add_some_colleagues: `J'aimerais ajouter des collègues`,
	you_dont_have_colleagues: `<p class="orange">Vous n'avez aucun collègue jusqu'à maintenant.</p>
														<p class="orange">Cliquez sur la barre <strong>Recherche d'un profil</strong> de la section <strong>Classement</strong>.</p>`,
	View: 'Voir',
	you_have_new_colleagues: `<div>Vous avez <span class="new-colleague">{incomingRelationships} nouvelle{s}</span> requête{s}</div>
														<div>de collègue{s} qui sont en attente.</div>`,
	top_players: `Les joueurs avec le plus grand nombre mensuel de commentaires "aimés" dans les scénarios.`,
	reference: 'Référence',
	less: 'moins',
	more: 'plus',
	show_less_more: 'Voir {what}',
	completed: 'Complétés',
	incomplete: 'Incomplets',
	cme_claimed: 'CME réclamé',
	claim_cme: 'Demander le CME',
	scenarios: 'scénarios',
	treated_patients: 'Patients traités',
	clinic_progress: 'Progrès clinique',
	see_my_accreditations: 'Voir mes accréditations',
	accreditations: 'Accréditations',
	active_since: 'Depuis {activeSince}',
	username_will_appear: `<p class="username">{userName}</p>
												<p>apparaîtra dans votre liste</p>
												<p>aussitôt la requête acceptée.</p>`,
	yes_send_request: 'Oui, je veux envoyer ma demande',
	want_colleague_request: `<p>Voulez-vous demander</p>
													<p class="username">à {userName}</p>
													<p>de correspondre avec vous ?</p>`,
	search_profile: 'Rechercher un profil',
	search_colleague: 'Rechercher un collègue',
	global_search_profile: `Recherche d'un profil parmi tous`,
	yes_remove: `Oui, je veux l'enlever`,
	want_to_remove: `<p>Voulez-vous enlever</p>
										<p class="username">{ userName }</p>
										<p>de vos collègues ?</p>
										<p>{userName} n'en sera pas averti.</p>`,
	no_reconsidered: `Non, j'ai changé d'avis`,
	yes_cancel: 'Oui, je veux arrêter de correspondre',
	cancel_with_user: `<p>Voulez-vous arrêter de correspondre</p>
									<p class="username">avec {userName} ?</p>
									<p>{userName} n'en sera pas averti.</p>`,
	colleagues: 'Collègues',
	leaderboard: 'Classement',
	your_profile: 'Votre profil',
	my_profile: 'Profil',
	save_changes: 'Sauvegarder les changements',
	quit_without_saving: 'Quitter sans sauvegarder',
	account_created_on: 'Compte créé le',
	hidden_from_players: 'Caché des joueurs',
	edit_profile: 'Éditer le profil',
	authorized_by: 'Autorisé par',
	consented: 'A consenti à recevoir des courriels',
	since_the_date_of: 'depuis le',
	practices_at: 'Exerce sa pratique à',
	is_certified: 'Ayant la certification de',
	known_as: 'Portant le nom de',
	we_hereby: `Nous, les membres de Level Up MD, déclarons que`,
	my_stats: 'Statistiques',
	my_stats_short: 'Stats',
	see_woman_patient: 'Voir la patiente',
	see_man_patient: 'Voir le patient',
	module: 'Module',
	difficulty: 'Difficulté',
	name: 'Nom',
	filters: 'Filtres',
	unaccredited: 'Non accrédités',
	accredited: 'Accrédités',
	patients: 'Patients',
	patient_selection: 'Sélection du patient',
	age: 'Âge',
	no_preferences: 'Pas de péférence',
	gender: 'Genre',
	estimated_difficulty: 'Difficulté estimée',
	no_game_found: 'Aucune partie trouvée',
	certificate_id: 'ID du certificat',
	patient_name: 'Nom du patient',
	scenario_name: 'Nom du scénario',
	module_name: 'Nom du module',
	performance_detection: 'Détection des performances...',
	continue: 'Continuer',
	animation_quality: `Qualité d'animation`,
	quality_reduced: `La qualit/ des animations a été réduite afin d'optimiser les performances sur votre appareil.`,
	recommandation: 'Recommandation',
	view_research_paper: 'Voir les réimpressions',
	published_on: 'Publié le',
	written_by: 'Écrit par',
	show_more: 'Voir plus',
	publication_date: 'Date de publication',
	author_name: `Nom de l'auteur`,
	research_paper_name: 'Nom de la réimpression',
	search_engine: 'Engin de recherche',
	newest_first: 'Du plus récent au plus ancien',
	search: 'Recherche',
	research_papers: `Réimpressions d'essais cliniques`,
	research_papers_documents: 'Réimpressions',
	no_research_papers: `Aucune réimpression d'essai clinique trouvée`,
	waiting_connection: 'En attente de connexion...',
	offline: 'HORS LIGNE',
	patient_case: 'Cas de patients',
	profile: 'Profil',
	resources: 'Ressources',
	refuse: 'Refuser',
	approve: 'Approuver',
	new_thread: 'Nouveau fil',
	responding_to: 'En réponse à',
	awaiting_comment: 'Ces commentaires sont en attente de révision.',
	deem_comment: `L'administrateur peut désapprouver les commentaires jugés inappropriés ou manquant de respect peuvent être refusés. Ils ne sont alors pas visibles aux autres joueurs.`,
	approving_comment: `Approuvez un commentaire permet aux autres joueurs de le voir dans la partie, de l'apprécier et d'y répondre.`,
	all_comments: `Vous trouverez ici tous les commentaires en attente d'approbation.`,
	comment_review: `Revue des commentaires`,
	no_comment_review: `Aucun commantaire à réviser`,
	welcome_patient_scenarios: 'Bienvenu aux scénarios du patient.',
	email_not_registered: 'Courriel non enregistré',
	request_sent: `<p>Un courriel avec les instructions</p><p>pour réinitialiser votre</p><p>mot de passe a été envoyé à</p>`,
	presented_by: `<p class="small">Présenté par</p><p>Studio</p><p>Affordance</p>`,
	sponsored_by: 'Commanditaire fondateur de la plateforme',
	alert_logout_title: 'Confimation de déconnexion',
	alert_logout_text: `Vous pourrez continuer à jouer en vous connectant à nouveau.`,
	alert_logout_confirm_text: 'Déconnexion',
	alert_logout_cancel_text: 'Annuler',

	administrator_access: 'Accès administrateur',
	research_papers_licences: 'Licences de documents de recherche',
	research_papers_licences_description: `Les utilisateurs peuvent accéder aux documents de recherche par l'intermédiaire de la section "Réimpressions d'essais cliniques" qui se trouve au deuxième étage de la clinique du jeu.<br>
		Les licences de ces documents sont disponibles en quantité limitée.<br>
		Vous pouvez ajouter autant de licences que nécessaire, à tout moment.
		Pour ce faire, utilisez les flèches dans les sections "Ajouter des licences" ci-dessous ou tapez simplement le nombre souhaité et appuyez sur "Ajouter". Elles seront mises à la disposition des utilisateurs.<br>
		Les documents de recherche pour lesquels il reste moins de 10 licences seront marqués d'un "!".`,
	remaining_licence: 'licence<br>restante',
	add_new_licences: 'Ajouter des licences',
	add: 'Ajouter',
	unlimited_access: 'Accès illimité',
	report_issue_title: 'Il y a un problème',
	send_message: 'Envoyer le message',
	enter_your_message: 'Entrez votre message',
	message_sent: 'Message envoyé',
	thank_you: 'Merci !',
	oneChoice: 'Un seul choix possible',
	multipleChoices: 'Plusieurs choix possibles',
	selectToRemove: 'Enlever',
	maximumReached: 'Enlever des choix pour en ajouter',
	andMore: 'et plus',
	information_summary: 'Résumé des informations',
	close: 'Fermer'
}
messages['vee-validate'].passwordConfirmation = { ...messages['vee-validate'].password }
messages['vee-validate'].terms_and_conditions.is_true = messages['vee-validate'].terms_and_conditions.required
messages['vee-validate'].hcp.is_true = messages['vee-validate'].hcp.required
messages['vee-validate'].user_name = messages['vee-validate'].userName
messages['vee-validate'].q2 = messages['vee-validate'].q1
messages['vee-validate'].q3 = messages['vee-validate'].q1
messages['vee-validate'].q4 = messages['vee-validate'].q1
messages['vee-validate'].q5 = messages['vee-validate'].q1

module.exports = {
	messages
}
