import Apollo from '@/apollo/apollo-client'
import { isUndefined } from 'lodash'
import { getValueOnPath } from '@/js/utils/utils'

// Récupère la liste des parties disponibles
const get_games = async ({ commit, dispatch }) => {
	dispatch('set_main_loader', { main_loader: true })
	dispatch('set_end_demo', { end_demo: false })
	const result = await Apollo.query('CLIENT_SECTION_BY', { slug: 'waiting_room' })
	if (result && result[0] && result[0].clientGames) {
		commit('EDIT_GAME_STATE', { section: result[0], games: result[0].clientGames })
	}
}

const get_games_filtered = async ({ state, commit, dispatch }, payload = {}) => {
	if (state.refetch === false) return
	const { scopes = '' } = payload
	await dispatch('set_end_demo', { end_demo: false })
	const filters = state.filters.with
	const variables = scopes ? { filters, scopes } : { filters }
	const games = await Apollo.query('CLIENT_GAME_FILTERED', variables)
	if (games && games.data) {
		commit('EDIT_GAME_STATE', { games: games.data })
		commit('RESET_GAME_REFETCH')
	}
	// if (Array.isArray(scopes) && scopes.includes('completed')) {
	// 	commit('SET_TOTAL_NUMBER_GAMES', {
	// 		games: await Apollo.query('CLIENT_GAME_FILTERED', { filters: state.filters.with })
	// 	})
	// } else {
	// 	commit('SET_TOTAL_NUMBER_GAMES', { games })
	// }
}

const set_game_filters = async ({ commit, state, dispatch, rootGetters }, { targetModel = null, tags = null, property = null }) => {
	if (!targetModel) return null
	const filters = await Apollo.query('CLIENT_FILTER_BY', tags ? { targetModel, tags } : { targetModel })
	commit('SET_GAME_FILTERS', { filters, property })
}

// Lance une partie et récupère l'étape en cours
const launch_game = async ({ commit, dispatch }, data) => {
	// dispatch('set_main_loader', { main_loader: true })
	if (isUndefined(data.prevent_init) || !data.prevent_init) commit('EDIT_GAME_CURRENT', { game: data.game, progression: false, step: false, question: false })
	const result = await Apollo.mutate('CREATE_PROGRESSION', { gameId: data.game.id })
	
	let progression = result
	let step = getValueOnPath(result, 'currentMark.step')
	
	dispatch('set_current_step', { step, progression })
	// if (data.prevent_init) dispatch('set_main_loader', { main_loader: false })
	return result
}



// Termine une étape puis passe à la suivante
const complete_current_step = async ({ commit, state, dispatch, rootGetters }) => {
	if (!rootGetters.app.loading) {
		const result = await Apollo.mutate('COMPLETE_STEP', { progressionId: state.current.progression.id })
		let step = getValueOnPath(result, 'nextStep.content')
		if (!step) step = getValueOnPath(result, 'content')

		if (!isUndefined(result.score)) {
			dispatch('set_current_score', { score: result.score })
			if (state.current.step.layout == 'trivia' && result.score === 1) {
				setTimeout(() => {
					dispatch('set_current_step', { step })
				}, 2000)
			} else
				dispatch('set_current_step', { step })
		} else
			dispatch('set_current_step', { step })

		if (state.current.completed != result.gameCompleted) commit('EDIT_GAME_CURRENT', { completed: result.gameCompleted })

		return result
	}
}



// Répond à une question puis passe à l'étape suivante
const send_answer = async ({ commit, state, dispatch, rootGetters }) => {
	if (!rootGetters.app.loading) {
		let answers_id = state.ui.active.selected_answers.map((a) => { return a.id })
		const result = await Apollo.mutate('SEND_ANSWER', {
			progressionId: state.current.progression.id,
			questionId: state.current.question.id,
			answers: answers_id
		})
		if (result && result.progression && result.progression.id) {
			complete_current_step({ commit, state, dispatch, rootGetters })
			return result.progression
		}
		return result
	}
}


// Récupère toutes les étapes et commentaires du jeu qui s'est déroulé
const get_end_of_scenario = async ({ state, commit }) => {
	if (state.current && state.current.progression && state.current.progression.id) {
		const result = await Apollo.query('CLIENT_USER_PROGRESSION', { id: state.current.progression.id })
		if (result) {
			let values = {}
			if (result.marks) values.marks = result.marks
			if (result.clientGame && result.clientGame.comments && result.clientGame.comments.data) values.comments = result.clientGame.comments.data
			if (values) commit('EDIT_GAME_CURRENT', values)
		}
	}
}


// Envoie un commentaire
const get_game_comments = async ({ state, commit }, data) => {
	const result = await Apollo.query('CLIENT_GAME_COMMENTS', { id: data.game_id })
	if (result && result.comments && result.comments.data) {
		commit('EDIT_GAME_CURRENT', { comments: result.comments.data })
	}
	return result
}


// Récupère la liste des commentaires à approuver
const get_pending_comments = async ({ state, commit }, data) => {
	const result = await Apollo.query('PENDING_COMMENTS')
	if (result && result.data) {
		commit('EDIT_GAME_STATE', { pending_comments: result.data })
	}
	return result
}


// Envoie un commentaire
const send_comment = async ({ state, commit }, data) => {
	const result = await Apollo.mutate('CREATE_CLIENT_COMMENT', { body: data.body, commentableElementId: data.element_id, commentableElementType: data.element_type })
	return result
}


// Like ou unlike un élément et retourne l'élément mis à jour
const toggle_like = async ({ state, commit }, data) => {
	const result = await Apollo.mutate('TOGGLE_CLIENT_LIKE', { likeableElementId: data.element_id, likeableElementType: data.element_type })
	if (result) return result
	return false
}


// Approv a _comment
const approve_comment = async ({ state, commit, dispatch }, data) => {
	const result = await Apollo.mutate('APPROVE_CLIENT_COMMENT', { id: data.id })
	if (result) {
		await dispatch('get_pending_comments')
		return result
	}
	return false
}


// Refuse a comment
const refuse_comment = async ({ state, commit, dispatch }, data) => {
	const result = await Apollo.mutate('REFUSE_CLIENT_COMMENT', { id: data.id })
	if (result) {
		await dispatch('get_pending_comments')
		return result
	}
	return false
}


// Delete a comment
const delete_comment = async ({ state, commit, dispatch }, data) => {
	const result = await Apollo.mutate('DELETE_CLIENT_COMMENT', { id: data.id })
	if (result) {
		return result
	}
	return false
}


// Search research papers
const search_research_papers = async ({ commit, rootGetters }, data) => {
	const filters = Object.values(rootGetters.app.research_papers_form)
		.filter((f) => f.filter_id && f.value)
		.map((f) => {
			return { filterId: f.filter_id, with: '{"value":"'+ f.value +'"}' }
		})

	let page = 1
	if (data && data.page) page = data.page
	let limit = 20
	if (data && data.limit) limit = data.limit

	const files = await Apollo.query('CLIENT_FILE_FILTERED_CONTEXT', { filters, page, limit })
	if (files && files.data) {
		let research_papers_meta = files.meta
		let research_papers = files.data
		if (page > 1) research_papers = [ ...rootGetters.app.research_papers, ...research_papers ]
		commit('EDIT_APP_STATE', { research_papers, research_papers_meta })
	} else if (page === 1)
		commit('EDIT_APP_STATE', { research_papers: [] })
}


export default {
	approve_comment,
	complete_current_step,
	get_end_of_scenario,
	get_game_comments,
	get_games,
	get_games_filtered,
	get_pending_comments,
	launch_game,
	refuse_comment,
	delete_comment,
	send_answer,
	send_comment,
	set_game_filters,
	toggle_like,
	search_research_papers,
}


