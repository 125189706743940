import { format, parseISO } from 'date-fns'

const formats = {
	readableDate: 'PPP'
}

const convertToStandardDate = date => {
	if (date.endsWith('Z')) return parseISO(date)
	return date
}

export default {
	install(Vue, options) {
		const { locale } = options
		Vue.dateFormat = function(date = null, formatStr = null) {
			if (!date || !formatStr) return date
			return format(convertToStandardDate(date), formats[formatStr], { locale })
		}
	}
}
