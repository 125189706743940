import Apollo from '@/apollo/apollo-client'
import {
	getRandomID,
	purify
} from './utils'

/**
 * returns media from passed medias function object
 * @param medias
 * @returns {function({id: *, width: *}): string}
 */
export const getLocalMedia = medias => ({ id, width }) => {
	return new Promise(async (resolve, reject) => {
		let src = medias.get({ id })
		if (!src) {
			const { url } = purify(await Apollo.query('CLIENT_MEDIA', { id }))
			if (!url) {
				reject()
			}
			src = url
		}
		resolve(src ? `<img src="${src}" width="${width}" />` : '')
	})
}

/**
 * returns AWS S3 medias in <img/> tag
 * @param id
 * @param width
 * @returns {Promise<string>}
 */
export const getStackMedia = async function({ id, width }) {
	const { url: src } = purify(await Apollo.query('CLIENT_MEDIA', { id }))
	return `<img id='${getRandomID()}' src='${src}' width='${width}' data-custom='id-${id}' />`
}

export const recomposeStackTag = async function({ tag, getMediaFunction }) {
	if (!tag || !getMediaFunction) return tag
	const array = tag.split(' ')
	const tagName = array.shift()
	const properties = array.reduce((a, c) => {
		const [ property, value ] = c.split('-')
		return Object.assign(a, {
			[property]: value
		})
	}, {})
	switch (tagName) {
		case 'img':
			const { id, width } = properties
			return await getMediaFunction.call(this, { id, width })
		default:
			return await(await new Promise((resolve) => resolve('')))
	}
}

/**
 * returns HTML text + images as medias are fetch from either local or AWS
 * @param html
 * @param getMediaFunction
 * @returns {Promise<string|*>}
 */
export const recomposeStackHtml = async function({ html, getMediaFunction = getStackMedia }) {
	if (!html) return html
	let tmp = String(html)
	let newHtml = ''
	while (tmp) {
		let len = tmp.length,
			newTag = '',
			prefix = '',
			suffix = '',
			tag = '',
			tagEnd = 0,
			tagStart = tmp.indexOf('[* ')
		if (tagStart > -1) {
			tagEnd = tmp.indexOf(' *]')
			prefix = tmp.substring(0, tagStart)
			tagStart += 3
			tag = tmp.substring(tagStart, tagEnd)
			tagEnd += 3
		} else {
			prefix = tmp.substring(0, len)
			tagEnd = len
		}
		if (tag) newTag = await recomposeStackTag.call(this, { tag, getMediaFunction })
		newHtml = `${newHtml}${prefix}${newTag}`
		tmp = tmp.substring(tagEnd, len)
		if (!tmp) newHtml =  `${newHtml}${suffix}`
	}
	return newHtml
}
