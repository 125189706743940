<template>
	<div class="ui-alert-confirm">
		<div class="bg" v-hammer:tap="close"></div>
		<div class="content">
			<div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg></div>
			<div v-if="alert_confirm.title" class="title">{{ alert_confirm.title }}</div>
			<div v-if="alert_confirm.text" class="text">{{ alert_confirm.text }}</div>
			<div class="buttons">
				<ui-button v-hammer:tap="close">{{ cancel_text }}</ui-button>
				<ui-button v-if="alert_confirm.confirm_dispatch" v-hammer:tap="validate" color="red">{{ confirm_text }}</ui-button>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ui-alert-confirm',
	props: {
		alert_confirm: { default: null }
	},
	computed: {
		cancel_text() {
			if (this.alert_confirm.cancel_text) return this.alert_confirm.cancel_text
			return "Cancel"
		},
		confirm_text() {
			if (this.alert_confirm.confirm_text) return this.alert_confirm.confirm_text
			return "Confirm"
		}
	},
	methods: {
		close() {
			this.$store.commit('EDIT_APP_STATE', { alert_confirm: null })
		},
		validate() {
			if (this.alert_confirm.confirm_dispatch) {
				this.$store.dispatch(this.alert_confirm.confirm_dispatch)
			}
			this.close()
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.ui-alert-confirm
	fixed 0
	flex center center
	padding 16px
	transition 0.25s easeOutQuart
	&.alert-confirm-enter, &.alert-confirm-leave-to
		opacity 0
		.content
			transform translateY(-24px)
	.bg
		absolute 0
		background-color alpha(#000, 75%)
	.content
		width 100%
		max-width 380px
		padding 24px 16px
		background-color #fff
		border-radius 8px
		text-align center
		transition 0.25s easeOutQuart
		flex center center
		flex-direction column
		.icon
			width 64px
			height 64px
			margin 0 0 8px 0
			border-radius 50%
			background #fff center center no-repeat
			background-size 56px
			color #F44336
			flex center center
			>>> svg
				display block
				width 100%
				height 100%
		.title, .text
			margin 0 0 16px 0
			line-height 1.4em
		.title
			text-transform uppercase
			font-weight 700
			color #F44336
			// font-size 1.8rem
			line-height 1.2em
		.buttons
			padding-top 8px
			>>> .ui-button
				margin 4px
				&.red
					.button
						background-color #F44336


</style>
