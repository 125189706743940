import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './modules/app'
import dev from './modules/dev'
import module from './modules/module'
import game from './modules/game'
import user from './modules/user'
import patients from './modules/patients'
import { client } from './modules/client.module'
import { schema } from './modules/schema.module'
import login from './modules/login.module'

const state = {

}

const store = new Vuex.Store({
	state,
	modules: {
		dev,
		app,
		module,
		game,
		user,
		patients,
		client,
		schema,
		login
	},
	strict: process.env.NODE_ENV !== 'production'
})

export default store
