import Vue from 'vue'
import { medias } from './store/medias'
import store from './store/store'
import App from './App.vue'
import router from './router'
import UiButton from '@/components/ui/ui-button'
import { VueHammer } from 'vue2-hammer'
import Scrollbar from '@/plugins/scrollbar'
import AlertConfirm from '@/plugins/alert-confirm'
import jquery from 'jquery'
import Fancybox from '@/plugins/fancybox'
import vuexI18n from 'vuex-i18n'
import { LANGUAGE } from './js/factory/language'
import { extendVeeValidate } from './js/vee-validate/validators'
import { messages as FR } from '@/js/constants/messagesFR'
import { messages as EN } from '@/js/constants/messagesEN'
import { enCA, fr } from 'date-fns/locale'
import dateFormat from './plugins/dates.js'
import {garbageCollector} from "@/js/utils/garbageCollector"
import {manageLottie} from "@/js/managers/manageLottie"
import StackMedias from '@/plugins/medias-plugin'

garbageCollector.add(manageLottie)

const language = LANGUAGE.getData() || 'en'
const locale = {
	en: enCA,
	fr
}[language]

window.jQuery = jquery
window.$ = jquery

VueHammer.config.domEvents = false
Vue.config.productionTip = false

Vue.use(vuexI18n.plugin, store)
Vue.i18n.add('en', EN)
Vue.i18n.add('fr', FR)
Vue.i18n.set(language)
Vue.use(VueHammer)
Vue.use(AlertConfirm)
Vue.use(Scrollbar)
Vue.use(Fancybox)
Vue.use(dateFormat, { locale })
Vue.use(StackMedias, {
	dbName: 'level-up-doctor',
	version: 1
})
Vue.component('ui-button', UiButton)

extendVeeValidate()

Vue.prototype.$medias = medias

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
