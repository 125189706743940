import Vue from 'vue'
import Hammer from 'hammerjs'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'

const Fancybox = {}


let set_fancybox = (el, binding) => {
	el.setAttribute('data-src', binding.value.src)

	el.mc = new Hammer.Manager(el)
	el.mc.add( new Hammer.Tap() )

	el.mc.on("tap", (e) => {
		let gallery = el.getAttribute('data-gallery')

		let $items = $(el)
		let index = 0
		if (gallery) {
			$items = $('[data-gallery="' + gallery + '"]')
			index = $items.index(el)
		}

		// console.log($items[0].getAttribute('data-src'))

		if ($items) {
			$.fancybox.open($items, {
				buttons: [ 'close' ],
				protect: true,
				infobar: false,
				toolbar: true
			}, index)
		}
	})
}

Fancybox.install = function (Vue, options) {

	let src = ''
	let fancybox = require('@fancyapps/fancybox')

	Vue.directive('fancybox', {
		inserted(el, binding) {
			set_fancybox(el, binding)
		},
		update(el, binding) {
			el.mc.destroy()
			set_fancybox(el, binding)
		}
	})

}

export default Fancybox
