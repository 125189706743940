<template>
	<div class="dev-helper">

		<div class="box">
			<div class="title"><span>LOAD GAME</span></div>
			<div class="content">

				<div class="line blocks">
					<label class="label">Game id :</label>
					<input type="text" class="input" v-model="config.game_id">
				</div>

				<div class="line buttons">
					<button class="button" @click="load_steps()">Load</button>
				</div>

			</div>
		</div>

		<div class="box" v-if="dev.steps.length">
			<div class="title"><span>STEPS</span></div>
			<div class="content">

				<div class="steps">
					<div v-for="step in dev.steps" class="step" :class="{ active: step.content.id == game.current.step.id }" @click="set_step(step)">
						{{ step.content.layout }}
					</div>
				</div>

				<div class="loader" v-show="app.loading"></div>

			</div>
		</div>

		<div class="box">
			<div class="title"><span>LAST REQUEST</span></div>
			<div class="content">

				<p>{{ dev.last_request.name }}</p>
				<p>Duration : {{ dev.last_request.duration / 1000 }}s</p>

			</div>
		</div>

		<div class="box">
			<div class="title"><span>CONFIG</span></div>
			<div class="content">

				<!-- <div class="line">
					<label class="label checkable" for="show_tuto"><span>Tuto : </span><input id="show_tuto" type="checkbox" v-model="config.show_tuto" @change="toggle_tuto()"></label>
				</div>

				<div class="line">
					<span>Device : </span>
					<select class="select" v-model="config.device">
						<option value="desktop">Desktop</option>
						<option value="mobile">Mobile</option>
						<option value="tablet">Tablet</option>
					</select>
					<button class="button refresh" @click="change_device(config.device)"><inline-svg :src="require('@/assets/img/refresh.svg')" /></button>
				</div> -->

				<div class="line">
					<span>Performance : </span>
					<select class="select" v-model="config.performance">
						<option value="low">Low</option>
						<option value="normal">Normal</option>
						<option value="high">High</option>
					</select>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'dev-helper',
	data() {
		return {
			config: {
				game_id: '5d7a958385db9e9c604d0950',
				// show_tuto: true,
				// device: 'desktop'
			}
		}
	},
	computed: {
		...mapGetters([ 'dev', 'app', 'game' ]),
		request_status() {
			const status = [ 'Loading', 'SetVariables', 'FetchMore', 'Refetch', 'Unknown', 'Poll', 'OK', 'Error' ]
			if (this.dev.last_request.status <= 8) return status[this.dev.last_request.status - 1]
			return "???"
		}
	},
	watch: {
		config: {
			handler: function(newValue, oldValue) {
				localStorage.setItem('eli-lilly-dev-config', JSON.stringify(newValue))
				if (newValue.performance != this.app.performance) this.change_performance(newValue.performance)
				// if (newValue.show_tuto == this.game.tuto.done) this.toggle_tuto()
				// if (newValue.device != this.app.device) this.change_device(newValue.device)
			},
			deep: true
		}
	},
	methods: {
		load_steps() {
			this.$store.dispatch('dev_load_steps', { game_id: this.config.game_id })
		},
		set_step(step) {
			this.$store.dispatch('dev_set_step', { game_id: this.config.game_id, step_id: step.id })
		},
		// toggle_tuto() {
		// 	this.$store.commit('EDIT_GAME_TUTO', { done: !this.config.show_tuto })
		// },
		// change_device(device) {
		// 	this.$store.dispatch('set_device', { device: device })
		// }
		change_performance(performance) {
			this.$store.dispatch('set_performance', { performance: performance })
		}
	},
	mounted() {
		if (localStorage.getItem('eli-lilly-dev-config')) {
			this.config = JSON.parse(localStorage.getItem('eli-lilly-dev-config'))
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.dev-helper
	position fixed
	top 0
	left 0
	height 100%
	width 230px
	color lighten(dark_light, 25%)
	font-size 12px
	line-height 1.5em
	z-index 10000
	padding-top 32px
	.box
		// margin 0 0 16px 0
		background-color #111
		.title
			background-color #000
			> span
				display inline-block
				padding 8px 16px
		.content
			padding 8px 16px
			.line
				display flex
				align-items center
				padding 4px 0
				&.blocks
					display block
				.label, .input
					display block
					width 100%
				.label
					margin 0 0 2px 0
					&.checkable
						line-height 18px
						height 18px
						> span
							float left
							margin 0 8px 0 0
						input
							float left
							transform translateY(3px)
				.input, .select
					display block
					width 100%
					height 32px
					padding 0 8px
					border none
					background alpha(#fff, 25%)
					font inherit
					color #fff
					&:focus
						background-color alpha(#fff, 75%)
						color #000
				.select
					display inline-block
					width auto
					margin 0 8px
					appearance none
				.button
					display inline-block
					padding 0 8px
					height 32px
					background-color #fff
					border none
					cursor pointer
					font inherit
					color dark
					&:active
						transform translateY(1px)
					&.refresh
						width 32px
						height 32px
						>>> svg
							position absolute
							left calc(50% - 12px)
							top calc(50% - 12px)
							height 24px
							width 24px
							path
								fill dark
			.steps
				padding 8px 0
				margin 0 -8px
				.step
					height 28px
					padding 0 8px
					cursor pointer
					line-height @height
					// transition transform 0.15s easeOutQuart
					&:hover
						color #fff
						background-color #191919
					&.active
						color #fff
						cursor default
						background-color #222
			.loader
				position absolute
				right 0
				top 0
				bottom 0
				left 0
				background alpha(#000, 50%) url(../../assets/img/button-loader.svg) center center no-repeat
				background-size 24px 24px

</style>
