<template>
	<div class="language-screen">
		<div class="bg"></div>
		<div class="content">
			<div class="wrap">

				<p class="title">Language - Langue :</p>
				<p class="description">{{ description_text }}</p>
				<p class="description sub">{{ sub_description_text }}</p>

				<div class="langs">
					<div class="lang" :class="{ selected: selected_lang === 'en' }" v-hammer:tap="() => select_lang('en')">
						<div class="flag">En</div>
						<div class="name">English</div>
						<div class="checkbox"></div>
					</div>
					<div class="lang" :class="{ selected: selected_lang === 'fr' }" v-hammer:tap="() => select_lang('fr')">
						<div class="flag">Fr</div>
						<div class="name">Français</div>
						<div class="checkbox"></div>
					</div>
				</div>

				<div class="buttons">
					<transition name="button">
						<div v-show="selected_lang" class="button-wrap">
							<ui-button :loading="loading" color="confirm" v-hammer:tap="submit">{{ submit_text }}</ui-button>
						</div>
					</transition>
				</div>

			</div>
		</div>
		<div class="hospital"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LANGUAGE } from '@/js/factory/language'
import UiButton from '@/components/ui/ui-button'

export default {
	name: 'language-screen',
	data() {
		return {
			loading: false,
			selected_lang: null
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		description_text() {
			return this.selected_lang === 'fr' ? 'Veuillez sélectionner votre langue de préférence' : 'Please select your language of preference'
		},
		sub_description_text() {
			return this.selected_lang === 'fr' ? 'Vous pourrez à nouveau changer la langue dans les paramètres du jeu' : 'You will be able to switch again in the game\'s settings'
		},
		submit_text() {
			return this.selected_lang === 'fr' ? 'Confirmer' : 'Confirm'
		}
	},
	methods: {
		select_lang(lang) {
			this.selected_lang = this.selected_lang === lang ? null : lang
		},
		submit() {
			if (this.selected_lang) {
				this.loading = true
				LANGUAGE.setData({ definition: 'Language', data: this.selected_lang })
				location.reload()
			}
		}
	},
	components: { UiButton }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'



.language-screen
	fixed 0
	flex center center
	background #0A1930
	color #fff
	z-index 3

	background #E5FBFF bottom center no-repeat
	background-size cover
	height 100%
	transition 0.25s easeOutQuart
	transition-delay 0.25s
	.bg
		absolute 0
		background url(../assets/img/login/clinic-bg-day.svg) bottom center no-repeat
		background-size cover

	.content
		position absolute
		left 0
		right 0
		top calc(50% - 250px)
		width 100%
		height 500px
		background-color alpha(#0A172B, 95%)
		.wrap
			position absolute
			right 60%
			// margin 0 100px 0 0
			width 410px
			height 100%
			flex center center
			flex-direction column
			text-align left
			.title
				width 100%
				margin 0 0 16px 0
				font-size 2rem
				font-weight 700
				line-height 32px
				text-transform uppercase
				border-bottom 2px solid #fff
			.description
				width 100%
				font-size 1.6rem
				line-height 1.2em
				&.sub
					opacity 0.5
			.langs
				width 100%
				flex center center
				flex-wrap wrap
				padding 16px 0 0 0
				margin 0 0 32px 0
				.lang
					flex center center
					flex-direction column
					width 50%
					height 200px
					opacity 0.5
					cursor pointer
					// background-color alpha(#fff, 10%)
					&:hover
						opacity 1
					&.selected
						opacity 1
						.checkbox
							&:after
								content ''
								absolute 4px
								background-color dark
								border-radius 50%
					.flag
						max-height 120px
						margin 0 0 8px 0
						font-size 12rem
						font-family serif
						line-height 1em
						border-bottom 2px solid #fff
					.name
						display block
						margin 0 0 16px 0
					.checkbox
						width 24px
						height 24px
						background-color #fff
						border-radius 50%

			.buttons
				width 100%
				height 64px
				text-align right
				.button-wrap
					transition transform 0.4s easeOutBack, opacity 0.2s easeOutQuart
					&.button-enter, &.button-leave-to
						transform translateY(24px)
						opacity 0
				>>> .ui-button .button
					height 48px
					padding 0 24px
					// text-transform uppercase
					// font-weight 700
					// font-size 1.6rem
					border-radius 12px



	.hospital
		position absolute
		left 43%
		bottom 6.2%
		width 700px
		height calc(95% - 64px)
		background url(../assets/img/login/clinic-front-day.svg) bottom center no-repeat
		background-size contain




@media (max-width: 1200px)
	.language-screen
		.hospital
			display block
			left 50%
			transform translateX(-50%)
			width 95%
			z-index 1
			height 100%
			max-height 90%
		.content
			top 50%
			transform translateY(-50%)
			height 450px
			z-index 2
			transition height 0.25s easeOutQuart
			.wrap
				right auto
				left 50%
				transform translateX(-50%)
				margin 0
				width 100%
				max-width (410px + 32px)
				padding 0 32px
				.langs
					margin 0 0 8px 0
					.lang
						.flag
							font-size 8rem
							line-height 1em






</style>
