import Vue from 'vue'
import Router from 'vue-router'
import { manageRoute } from '@/js/managers/manageRoute'
import {garbageCollector} from "@/js/utils/garbageCollector"

Vue.use(Router)

const router = new Router({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/view-main.vue'),
			meta: { loadingScreen: true }
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/view-login.vue'),
			meta: { loadingScreen: true },
		},
		{
			path: '/signup',
			name: 'signup',
			component: () => import('@/views/view-signup.vue'),
			meta: { loadingScreen: true }
		},
		{
			path: '/office',
			name: 'office',
			component: () => import('@/views/view-office.vue'),
			meta: { loadingScreen: true },
			children: [
				{
					path: 'profile',
					name: 'office-profile'
				},
				{
					path: 'leaderboard',
					name: 'office-leaderboard'
				},
				{
					path: 'stats',
					name: 'office-stats'
				}
			]
		},
		{
			path: '/resources',
			name: 'resources',
			component: () => import('@/views/view-resources.vue'),
			meta: { loadingScreen: true },
			children: [
				{
					path: 'research-papers',
					name: 'resources-research-papers'
				}
			]
		},
		{
			path: '*',
			component: () => import('@/views/view-login.vue')
		}
	],
})

const _route = { ...manageRoute({}) }
const { func: { beforeEach } = {} } = _route

router.beforeEach(async (to, from, next) => {
	setTimeout(() => {
		garbageCollector.destroy()
	}, 1000)
	await beforeEach(to, from, next)
})

export default router
