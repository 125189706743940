import Vue from 'vue'
import router from '@/router'



// === STATE =====================================================================================
const state = {
	loading: true,
	main_loader: true,
	online: true,
	device: 'mobile',
	performance: 'high', // low / medium / high
	settings: false,
	day_moment: 'day',
	end_demo: false,
	administrator_access_visible: false,
	notifications: [],
	alert_confirm: null,
	research_papers_licences: [],
	research_papers: [],
	research_papers_meta: {},
	research_papers_form: {
		name: { filter_id: "", value: "" },
		author: { filter_id: "", value: "" },
		published_after: { filter_id: "", value: "" },
		published_before: { filter_id: "", value: "" },
		// journal: { filter_id: "", value: "" }
	}
}








// === GETTERS =====================================================================================
const getters = {
	app(state) { return state },
}







// === ACTIONS =====================================================================================
const actions = {

	async init_app({ commit, dispatch }) {
		let perf = localStorage.getItem('performance')
		if (perf && ['low', 'medium', 'high'].includes(perf)) commit('EDIT_APP_STATE', { performance: perf })

		let hour = new Date().getHours()
		// hour = 6
		if (router.app.$route.name == 'night') hour = 6
		if (hour < 8 || hour > 20) commit('EDIT_APP_STATE', { day_moment: 'night' })

		// await dispatch('get_current_user')
		const { getters: { user: { logged_user: { reviewer } = {} } = {} } = {} } = this
		if (reviewer) await dispatch('get_pending_comments')
	},
	
	async getUserAndComments({ commit, dispatch }) {
		await dispatch('get_current_user')
		const { getters: { user: { logged_user: { reviewer } = {} } = {} } = {} } = this
		if (reviewer) await dispatch('get_pending_comments')
	},

	set_device({ commit }, data) {
		commit('EDIT_APP_STATE', { device: data.device })
	},

	set_main_loader({ commit }, data) {
		commit('EDIT_APP_STATE', { main_loader: data.main_loader })
	},

	set_end_demo({ commit }, data) {
		commit('EDIT_APP_STATE', { end_demo: data.end_demo })
	},

	set_performance({ commit }, data) {
		commit('EDIT_APP_STATE', { performance: data.performance })
		localStorage.setItem('performance', data.performance)
	},

	edit_online({ commit }, data) {
		commit('EDIT_APP_STATE', { online: data.online })
	},

	set_settings({ commit }, data) {
		commit('EDIT_APP_STATE', { settings: data.settings })
	},

	add_notification({ commit, state }, data) {
		commit('ADD_NOTIFICATION', { notification: data })
		setTimeout(function() {
			commit('REMOVE_NOTIFICATION', { notification: data })
		}, 5000)
	},
	

	remove_notification({ commit }, data) {
		commit('REMOVE_NOTIFICATION', { notification: data.notification })
	},

	


}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_APP_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_RESEARCH_PAPER_LICENCE(state, data) {
		if (data.licence && data.licence.accessibleSettings) {
			Vue.set(data.licence, 'accessibleSettings', data.accessibleSettings)
		}
	},

	EDIT_RESEARCH_PAPERS_FORM(state, data) {
		for (var i in data) {
			Vue.set(state.research_papers_form, i, data[i])
		}
	},

	SET_LOADING(state, data) {
		Vue.set(state, 'loading', data.loading)
	},

	ADD_NOTIFICATION(state, data) {
		if (!data.notification.id) data.notification.id = Math.random().toString(36).substr(2, 9)
		state.notifications.push(data.notification)
	},

	REMOVE_NOTIFICATION(state, data) {
		var index = state.notifications.findIndex((n) => n == data.notification)
		if (index > -1) state.notifications.splice(index, 1)
	},

}

export default { state, getters, actions, mutations }
