<template>
	<div class="loading-screen">

		<div class="bg" :style="{ width: size + 'px', height: size + 'px' }"></div>

		<div class="content">
			<div class="main-loader"></div>
			<div class="presented">
				<div class="text" v-html="$t('presented_by')"/>
				<div class="logo"></div>
			</div>
		</div>

		<div class="partner">
			<div class="text">
				<p class="small">{{ $t('sponsored_by')}}</p>
				<p>Eli Lilly</p>
				<p>Canada Inc.</p>
			</div>
			<div class="logo"></div>
		</div>

		<div v-show="app.main_loader" class="loading-images" id="loading-images">
			<div class="text"></div>
			<div class="bar"><div class="progress"></div></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'loading-screen',
	data() {
		return {
			size: 0
		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	created() {
		const multiplier = this.app.device === 'mobile' ? 2 : 1.5
		const w = window.innerWidth * multiplier
		const h = window.innerHeight * multiplier

		if (w > h) this.size = w
		else this.size = h
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

#app.mobile
	.loading-screen
		.content
			flex-direction column
			transform translateY(-15%)
			.main-loader
				margin 0 0 40px 0
				padding 0
				border none
			.presented
				height 100px
				.text
					margin 0 16px 0 0
					font-size 2rem
					line-height 24px
		.partner
			right auto
			left 50%
			transform translateX(-50%)
			bottom 64px
			height 48px
			.text
				margin 0 8px 0 0
				font-size 1.4rem
				line-height 16px
				.small
					font-size 1.2rem

		#loading-images
			bottom 32px
			.text
				display none

.loading-screen
	fixed 0
	color #fff
	font-family arial
	user-select none
	z-index 3

	&.loading-screen-enter-active
		transition 0.75s easeOutQuart
		.bg
			transition 0.75s easeOutQuart
			will-change transform
		.content, .partner, .loading-images
			transition opacity 0.5s easeOutQuart
			transition-delay 0.25s
	&.loading-screen-enter
		.bg
			transform translate(100%, 100%)
		.content, .partner, .loading-images
			opacity 0

	&.loading-screen-leave-active
		transition 0.25s easeOutQuart
	&.loading-screen-leave-to
		opacity 0

	.bg
		position absolute
		left 50%
		top 50%
		transform translate(-50%, -50%)
		// bottom -25%
		// right -25%
		// width 150%
		// height 150%
		background #0A1930
		transform-origin bottom right
		border-radius 50%
	.content
		height 100%
		width 100%
		flex center center
		transform translateY(-10%)
		.main-loader
			height 120px
			width 250px
			background url(../assets/img/main-loader.gif) center center no-repeat
			background-size contain
			margin 0 40px 0 0
			padding 0 40px 0 0
			border-right 2px solid #fff
		.presented
			height 150px
			flex center center
			.text
				flex center flex-end
				flex-direction column
				height 100%
				margin 0 24px 0 0
				text-align right
				white-space nowrap
				font-size 2.4rem
				line-height 28px
				.small
					margin 0 0 16px 0
					font-weight 400
					font-size 1.8rem
					opacity 0.5
			.logo
				height 100%
				width 120px
				background url(../assets/img/affordance-logo.svg) center center no-repeat
				background-size contain

	.partner
		position absolute
		right 80px
		bottom 80px
		height 64px
		text-align center
		flex center center
		.text
			height 100%
			margin 0 16px 0 0
			line-height 20px
			text-align right
			white-space nowrap
			font-size 1.6rem
			.small
				margin 0 0 4px 0
				font-weight 400
				font-size 1.4rem
				opacity 0.5
		.logo
			height 100%
			width 112px
			background url(../assets/img/lilly-logo.svg) bottom center no-repeat
			background-size contain
			transform translateY(13px)

	#loading-images
		display none
		position absolute
		left 0
		right 0
		bottom 24px
		text-align center
		font-size 1.4rem
		text-transform uppercase
		line-height 16px
		.text
			margin 0 0 8px 0
		.bar
			display block
			margin 0 auto
			height 4px
			width 250px
			background-color darken(dark, 25%)
			border-radius 4px
			overflow hidden
			.progress
				position absolute
				left 0
				top 0
				height 100%
				background-color yellow
				transition width 0.1s linear


</style>
