import Vue from 'vue'
import gameActions from '@/store/modules/actions/game-actions'
import { cloneDeep } from 'lodash'
import { getValueOnPath } from '@/js/utils/utils'
import { getLocalMedia, recomposeStackHtml } from '@/js/utils/recompose-stack-html'
import { medias } from '../medias'
import { setModuleFiltersWith } from './common/functions'

// === STATE =====================================================================================
const default_state = {
	nbGames: null,
	refetch: true,
	defaultFilters: {},
	ui: {
		character_animation: false,
		patient_report: false,
		active: {
			answer: false,
			selected_answers: [],
			patient_report_visible: false,
			patient_report_tab: false,
			modal: false,
			learn_more: false,
			reply_to: false
		}
	},
	current: {
		game: false,
		progression: false,
		step: false,
		question: false,
		score: 0,
		completed: false,
		comments: [],
		marks: [],
	},
	section: false,
	games: [],
	pending_comments: [],
	glossary: null,
	tuto: {
		done: true,
		current_step: 1,
		steps: [
			{
				id: 1,
				text: "Welcome to the patient scenarios.",
				params: { step: 'presentation', position: { x: 'right', y: 'top' } }
			},
			{
				id: 2,
				text: "Patients will come to you with situations relevant to your practice.",
				params: { step: 'presentation', position: { x: 'right', y: 'top' } }
			},
			{
				id: 3,
				text: "On your tablet, you can read a summary of information about the patient.",
				params: { step: 'presentation', position: { x: 'right', y: 'top' } }
			},
			{
				id: 4,
				text: "Use the tabs to cycle between the various information.",
				params: { step: 'presentation', position: { x: 'right', y: 'top' } }
			},
			{
				id: 5,
				text: "Once you're done reading the Patient Report, you can close it by using the Done button.",
				params: { step: 'presentation', position: { x: 'right', y: 'center' } }
			},
			{
				id: 6,
				text: "You're now ready to go! Click the coloured buttons below to see the possible answers and try to select the correct one.",
				params: { step: 'trivia', position: { x: 'right', y: 'center' } }
			}
		]
	}
}

const state = cloneDeep(default_state)

// === GETTERS =====================================================================================
const getters = {
	game: function(state) { return state },
	glossary(state) { return state.glossary },
	nbGames(state) { return state.nbGames},
	gameFilters(state) { return state.filters },
}

// === ACTIONS =====================================================================================
const actions = {
	...gameActions,
	
	init({ commit, dispatch }, data) {
		commit('EDIT_GAME_STATE', cloneDeep(default_state))
		commit('EDIT_GAME_UI', { character_animation: false })
		commit('EDIT_APP_STATE', { main_loader: true, patients_visible: false, selected_game: false, end_demo: false })
	},
	
	set_game_default_filters({ state, commit }, { property = null, value = null}) {
		if (!property) return
		commit('SET_GAME_DEFAULT_FILTERS', { property, value })
	},
	
	set_game_filters_with({ state, commit }, { values = [], needed = [] }) {
		commit('SET_GAME_FILTERS_WITH', { values, needed })
		commit('REFETCH_GAME')
	},
	
	set_active_answer({ commit }, data) {
		commit('EDIT_GAME_UI_ACTIVE', { answer: data.answer })
	},
	
	toggle_selected_answer({ commit }, data) {
		let selected_answers = []
		let index = state.ui.active.selected_answers.findIndex((a) => { return a.id == data.answer.id })
		if (index > -1)
			selected_answers = state.ui.active.selected_answers.filter((a) => { return a.id != data.answer.id })
		else
			// selected_answers = [...state.ui.active.selected_answers, data.answer]
			selected_answers = [data.answer] // Pour la démo, on ne peut sélectionner qu'une réponse

		commit('EDIT_GAME_UI_ACTIVE', { selected_answers })
	},

	set_active_patient_report({ commit }, data) {
		commit('EDIT_GAME_UI_ACTIVE', { patient_report_visible: data.active })
	},

	edit_patient_report_tab({ commit }, data) {
		commit('EDIT_GAME_UI_ACTIVE', { patient_report_tab: data.tab })
	},

	set_active_modal({ commit }, data) {
		commit('EDIT_GAME_UI_ACTIVE', { modal: data.modal })
	},

	set_active_learn_more({ commit }, data) {
		commit('EDIT_GAME_UI_ACTIVE', { learn_more: data.learn_more })
	},

	async show_current_question({ dispatch, state }, data) {
		let animation = ''
		if (state.current.question) {
			if (data && data.animation) animation = data.animation
			let text = state.current.question.contentTexts.find((t) => {
				return t.tags.includes('question')
			})
			const html = await recomposeStackHtml({ html: text.html, getMediaFunction: getLocalMedia(medias)})
			dispatch('set_active_modal', {
				modal: {
					character: { name: getValueOnPath(state, 'ui.character_animation.document.title') },
					text: html,
					button: { text: Vue.i18n.translate('continue') },
					animation
				}
			})
		}
	},

	next_tuto_step({ commit, state }) {
		let done = false
		let new_step = state.tuto.current_step + 1
		if (new_step > state.tuto.steps.length) done = true
		commit('EDIT_GAME_TUTO', { current_step: new_step, done })
	},

	async set_current_step({ commit, state, dispatch }, data) {
		if (data.step) {
			let step = data.step
			let ui_active = { answer: false, selected_answers: [], patient_report_visible: false }

			let values = { step, question: false }
			if (data.progression) values.progression = data.progression

			// Patient report
			if (step.layout == 'presentation') {
				commit('EDIT_GAME_CURRENT', values)
				commit('EDIT_GAME_UI', { patient_report: step })
				ui_active.patient_report_visible = true
				ui_active.modal = false
			}
			
			// Trivia
			else if (step.layout == 'trivia') {
				values.question = step.questions[0]
				commit('EDIT_GAME_CURRENT', values)
				ui_active.modal = false
			}

			// Display patient dialog
			else if (step.layout == 'feedback') {
				commit('EDIT_GAME_CURRENT', values)
				let name = getValueOnPath(state, 'ui.character_animation.document.title')
				if (state.current.game.document && state.current.game.document['patient name']) name = state.current.game.document['patient name']
				if (!name) {
					const media = step.clientMedias.find((m) => { return m.tags.includes('character') })
					if (media && media.document && media.document.title) name = media.document.title
				}
				const text = await recomposeStackHtml({ html: step.contentTexts[0].html, getMediaFunction: getLocalMedia(medias) })
				ui_active.modal = {
					character: { name },
					text,
					button: { text: Vue.i18n.translate('continue') },
					animation: 'typewriter'
				}
			}

			// Display generic dialog box
			else if (step.layout == 'feedback_no_image') {
				commit('EDIT_GAME_CURRENT', values)
				const text = await recomposeStackHtml({ html: step.contentTexts[0].html, getMediaFunction: getLocalMedia(medias) })
				ui_active.modal = {
					character: false,
					text,
					button: { text: Vue.i18n.translate('continue') },
					animation: false
				}
			}

			// Display generic dialog box
			else if (step.layout == 'intro') {
				commit('EDIT_GAME_CURRENT', values)
			}
			
			// Result
			else if (step.layout == 'result') {
				commit('EDIT_GAME_CURRENT', values)
				ui_active.modal = false
			}

			// commit('ADD_STEP_HISTORY', ui_active)
			commit('EDIT_GAME_UI_ACTIVE', ui_active)
			dispatch('on_step_changed')
		} else
			console.log('NO STEP FOUND', data)
	},

	set_current_score({ commit }, data) {
		commit('EDIT_GAME_CURRENT', { score: data.score })
	},

	on_step_changed({ commit, state, dispatch }) {
		let step = state.current.step
		let medias = state.current.step.clientMedias

		// Update character
		for (var i in medias) {
			if (medias[i].tags.includes('character')) {
				if (!state.ui.character_animation || medias[i].id != state.ui.character_animation.id) {
					commit('EDIT_GAME_UI', {
						character_animation: medias[i]
					})
				}
			}
		}

		if (step.layout == 'presentation' || step.layout == 'intro') commit('EDIT_GAME_UI', { character_animation: false })
	}
}

// === MUTATIONS =====================================================================================
const mutations = {
	SET_TOTAL_NUMBER_GAMES(state, { games }) {
		Vue.set(state, 'nbGames', games?.data.length || 0)
	},
	SET_GAME_DEFAULT_FILTERS(state, { property, value }) {
		state.defaultFilters[property] = value
	},
	RESET_GAME_REFETCH() {
		state.refetch = false
	},
	REFETCH_GAME(state) {
		state.refetch = true
	},
	SET_GAME_FILTERS(state, { filters, property = 'all' }) {
		state.filters = Object.assign({}, state.filters, {
			[property]: filters
		})
		state.filters.default = state.filters.all.filter(x => x.tags.includes('default'))
	},
	
	SET_GAME_FILTERS_WITH(state, { values = [], needed = [] }) {
		setModuleFiltersWith({ state, values, needed })
	},

	EDIT_GAME_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},

	EDIT_GAME_UI(state, data) {
		for (var i in data) {
			Vue.set(state.ui, i, data[i])
		}
	},

	EDIT_GAME_UI_ACTIVE(state, data) {
		for (var i in data) {
			Vue.set(state.ui.active, i, data[i])
		}
	},

	EDIT_GAME_CURRENT(state, data) {
		for (var i in data) {
			Vue.set(state.current, i, data[i])
		}
	},

	EDIT_GAME_TUTO(state, data) {
		for (var i in data) {
			Vue.set(state.tuto, i, data[i])
		}
	},

	EDIT_COMMENT(state, data) {
		for (var i in data) {
			if (i != 'comment') {
				Vue.set(data.comment, i, data[i])
			}
		}
	}

}

export default { state, getters, actions, mutations }
