const messages = {
	'Female': 'woman',
	'Male': 'man',
	loading_images: 'Loading assets ...',
	reset: 'reset',
	to: 'To',
	from: 'From',
	back: 'Back',
	home: 'Home',
	login: 'login',
	signup: 'signup',
	'create-account': 'Create an account',
	email: 'email',
	'e-mail': 'e-mail',
	password: 'password',
	passwordConfirmation: 'Password Confirmation',
	firstName: 'First Name',
	lastName: 'Last Name',
	familyName: 'Last Name',
	clinicName: 'Clinic Name',
	save: 'save',
	yes: 'yes',
	No: 'no',
	next: 'next',
	confirm: 'Confirm',
	submit: 'Submit',
	deny: 'Deny',
	username: 'Username',
	'practice-name': 'Name your virtual clinic',
	'add one more': 'Add one more',
	forgot_password: 'I forgot my password',
	'Password reset': 'Password reset',
	not_supported_screen_size: `Please rotate your smartphone or zoom out.`,
	practice_field: 'Practice Field',
	'practice_fields': {
		'general-practitioner': 'General Practitioner',
		family_medicine: 'Family Medicine',
		rheumatologist: 'Rheumatologist',
		neurologist: 'Neurologist',
		dermatologist: 'Dermatologist',
		cardiologist: 'Cardiologist',
		nurse: 'Nurse',
		pharmacist: 'Pharmacist',
		other: 'Other',
	},
	'vee-validate': {
		news_emails: {
			required: ''
		},
		practice_field: {
			required: 'Please select your practice field',
		},
		terms_and_conditions: {
			required: 'Please read the Terms and Conditions',
		},
		hcp: {
			required: 'Please certify that you are an HCP',
		},
		email: {
			required: 'Please fill out the e-mail field',
			invalid: 'Please enter a valid e-mail address',
		},
		firstName: {
			required: 'Please fill out your First Name',
			'length-big': 'Max of {max} characters',
			alpha: 'Please enter only letters',
		},
		lastName: {
			required: 'Please fill out your Family Name',
			'length-big': 'Max of {max} characters',
			alpha: 'Please enter only letters',
		},
		userName: {
			'length-big': 'Max of {max} characters',
			alpha_num: 'Only letters and digits',
			required: 'Please fill out the username field',
			invalid: 'Please enter a valid username',
		},
		password: {
			required: 'Please fill out the password field',
			'length-small': 'The password must be, at least, {min} letters, digits or symbols',
			'length-big': 'The password must be, at most, {max} letters, digits or symbols',
			confirmed: 'The Password Confirmation do not match the Password',
			notStrong: `Must be at least of 8 characters: 1 lowercase letter; 1 uppercase letter; 1 digit; some special characters.`
		},
		'practice-name': {
			required: 'Please fill out the practice-name field',
			'length-big': 'The practice name must be, at most, {max} characters, or symbols.',
		},
		'clinicName': {
			'length-big': 'Max of {max} characters'
		},
		'please-select-expertise': {
			required: 'Your expertise is required',
		},
		q1: {
			required: 'Please give your answer',
		}
	},
	date_missing: `Date missing`,
	no_practice_name: 'No practice name',
	practice_field_missing: 'Practice field missing',
	user_name_missing: 'Username missing',
	add_colleague: 'Add colleague',
	remove_colleague: 'Remove colleague',
	language: 'Language',
	see_completed: 'See completed patient cases',
	restart_game: 'The game will be restarted to apply changes.',
	ultra_quality: 'Ultra',
	high_quality: 'High',
	standard_quality: 'Standard',
	settings: 'Settings',
	male: 'Male',
	female: 'Female',
	archives: 'Archives',
	the_staff_room: 'The staff room',
	invalid_credentials: 'Invalid credentials',
	disclaimer: 'Disclaimer',
	stamp_for_approval: 'Stamp for<br>approval',
	report_issue: 'Report<br>an issue',
	reply_to: 'Reply to',
	start: 'start',
	share_your_thoughts: 'Share your thoughts!',
	edit_comment: 'Edit comment',
	delete_comment: 'Delete comment',
	delete: 'Delete',
	reply: 'reply',
	replies: 'replies',
	Reply: 'Reply',
	cancel: 'Cancel',
	comments: 'Comments',
	about_your_answer: 'About your answer',
	answer: 'Answer',
	scenario_timeline: 'Scenario timeline',
	question: 'Question',
	OK: 'OK',
	comment: 'Comment',
	recap: 'Recap',
	result: 'Result',
	select: 'Select',
	unselect: 'Unselect',
	select_your_answer: 'Select your answer',
	done: 'Start',
	summary: 'Summary',
	end_of_scenario: 'End of scenario',
	next_page: 'Next page',
	click_here_to_continue: 'Click here to continue',
	request_denied: 'Request denied',
	colleague_added: 'Colleague added',
	add_some_colleagues: 'I would like to add some colleagues',
	you_dont_have_colleagues: `<p class="orange">You don't have any colleagues as of yet.</p>
														<p class="orange">Click on the <strong>Search for a profile</strong> search bar of the <strong>Leaderboard</strong> section.</p>`,
	View: 'View',
	you_have_new_colleagues: `<div>You have <span class="new-colleague">{incomingRelationships} new</span> colleague{s}</div>
														<div>request{s} awaiting</div>`,
	top_players: `Top players with the most "likes" this month from comments on patient scenarios.`,
	reference: 'Reference',
	less: 'less',
	more: 'more',
	show_less_more: 'Show {what}',
	completed: 'Completed',
	incomplete: 'Incomplete',
	cme_claimed: 'CME claimed',
	claim_cme: 'Claim CME',
	scenarios: 'scenarios',
	treated_patients: 'Treated Patients',
	clinic_progress: 'Clinic progress',
	see_my_accreditations: 'View my accreditations',
	accreditations: 'Accreditations',
	active_since: 'Active since {activeSince}',
	username_will_appear: `<p class="username">{userName}</p>
												<p>will appear in your colleagues</p>
												<p>once the request is accepted.</p>`,
	yes_send_request: 'Yes, I want to send the request',
	want_colleague_request: `<p>Do you wish to send</p>
													<p class="username">{userName}</p>
													<p>a colleague request?</p>`,
	search_profile: 'Search for a profile',
	search_colleague: 'Search for a colleague',
	global_search_profile: 'Global search for a profile',
	yes_remove: 'Yes, I want to remove him/her',
	want_to_remove: `<p>Are you sure you want to remove</p>
										<p class="username">{ userName }</p>
										<p>from your colleagues?</p>
										<p>{userName} will not be notified.</p>`,
	no_reconsidered: `No, I've reconsidered`,
	yes_cancel: 'Yes, I want to cancel the request',
	cancel_with_user: `<p>Are you sure you want to cancel</p>
									<p>incoming or outgoing request with</p>
									<p class="username">{userName} ?</p>
									<p>{userName} will not be notified.</p>`,
	colleagues: 'Colleagues',
	leaderboard: 'Leaderboard',
	your_profile: 'Your office',
	my_profile: 'Profile',
	save_changes: 'Save changes',
	quit_without_saving: 'Quit without saving',
	account_created_on: 'Account created on',
	hidden_from_players: 'Hidden from players',
	edit_profile: 'Edit profile',
	authorized_by: 'Authorized by',
	consented: 'Has consented to email communication',
	since_the_date_of: 'since the date of',
	practices_at: 'Practices at',
	is_certified: 'Is certified as a',
	known_as: 'Known as',
	we_hereby: `We, the members of Level Up MD, hereby decree that`,
	my_stats: 'Stats',
	my_stats_short: 'Stats',
	see_woman_patient: 'See patient',
	see_man_patient: 'See patient',
	module: 'Module',
	difficulty: 'Difficulty',
	name: 'Name',
	filters: 'Filters',
	unaccredited: 'Unaccredited',
	accredited: 'Accredited',
	patients: 'Patients',
	patient_selection: 'Patient selection',
	age: 'Age',
	no_preferences: 'No preferences',
	gender: 'Gender',
	estimated_difficulty: 'Estimated Difficulty',
	no_game_found: 'No games found',
	certificate_id: 'Certificate ID',
	patient_name: 'Patient Name',
	scenario_name: 'Scenario Name',
	module_name: 'Module Name',
	performance_detection: 'Performance detection...',
	continue: 'Continue',
	animation_quality: 'Animation quality',
	quality_reduced: `The quality of the animations has been reduced to optimize the performance on your device.`,
	recommandation: 'Recommandation',
	view_research_paper: 'View reprint',
	published_on: 'Published on',
	written_by: 'Written by',
	show_more: 'Show more',
	publication_date: 'Publication date',
	author_name: `Author's Name`,
	research_paper_name: 'Clinical Trial Reprint Name',
	search_engine: 'Search engine',
	newest_first: 'Newest first',
	search: 'Search',
	research_papers: 'Clinical Trial Reprints',
	research_papers_documents: 'Clinical Trial Reprints',
	no_research_papers: 'No Clinical Trial Reprint found',
	waiting_connection: 'Waiting for connection...',
	offline: 'OFFLINE',
	patient_case: 'Patient cases',
	profile: 'Profile',
	resources: 'Resources',
	refuse: 'Refuse',
	approve: 'Approve',
	new_thread: 'New thread',
	responding_to: 'Responding to',
	awaiting_comment: 'These comments are awaiting revision.',
	deem_comment: `Comments that are deemed as innappropriate, disrespectful or mean spirited can be refused. These will be hidden from all other users in the game. The choice is left at the discretion of the administrator.`,
	approving_comment: 'Approving a comment will display it in the game, allowing other players to see it in the game as well as like and/or respond to it.',
	all_comments: 'Here you will find all the comments awaiting approval.',
	comment_review: `"Comment" review`,
	no_comment_review: `No "Comment" review`,
	welcome_patient_scenarios: 'Welcome to the patient scenarios.',
	email_not_registered: 'E-mail not registered',
	request_sent: `<p>An e-mail with instructions</p><p>on how to reset your</p><p>password has been sent to</p>`,
	presented_by: `<p class="small">Presented by</p><p>Affordance</p><p>Studio</p>`,
	sponsored_by: 'Founding Platform Sponsor',
	alert_logout_title: 'Confirm log out',
	alert_logout_text: `You will be able to resume play by logging back in.`,
	alert_logout_confirm_text: 'Logout',
	alert_logout_cancel_text: 'Cancel',

	administrator_access: 'Administrator access',
	research_papers_licences: 'Research papers licences',
	research_papers_licences_description: `Through the research paper section found on the second floor of the game's clinic, users can gain access to research papers.<br>
						These papers licences are available in limited quantity. You can add as many licences has needed, at any point in time.
						To do so, use the arrows in the "Add New Licences" sections below or simply type the desired number an press "Add".<br>
						They will be made available for users. Research papers with less than 10 licences remaining will be marked with "!".`,
	remaining_licence: 'Remaining<br>licence',
	add_new_licences: 'Add new licences',
	add: 'Add',
	unlimited_access: 'Unlimited access',
	report_issue_title: 'Report an issue',
	enter_your_message: 'Enter your message',
	send_message: 'Send message',
	message_sent: 'Message sent',
	thank_you: 'Thank you !',
	oneChoice: 'Only one choice possible',
	multipleChoices: 'Several choices possible',
	selectToRemove: 'Remove',
	maximumReached: 'Remove choices to add others',
	andMore: 'and more',
	information_summary: 'Information Summary',
	close: 'Close',
}
messages['vee-validate'].passwordConfirmation = { ...messages['vee-validate'].password }
messages['vee-validate'].terms_and_conditions.is_true = messages['vee-validate'].terms_and_conditions.required
messages['vee-validate'].hcp.is_true = messages['vee-validate'].hcp.required
messages['vee-validate'].user_name = messages['vee-validate'].userName
messages['vee-validate'].q2 = messages['vee-validate'].q1
messages['vee-validate'].q3 = messages['vee-validate'].q1
messages['vee-validate'].q4 = messages['vee-validate'].q1
messages['vee-validate'].q5 = messages['vee-validate'].q1

module.exports = {
	messages
}
