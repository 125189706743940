<template>
	<div id="app" :class="[ app.device, 'perf-' + app.performance, touch_class ]">

		<!-- <keep-alive> -->
		<transition name="route">
			<router-view v-if="!app.main_loader" @loaded="route_loaded" />
		</transition>
		<!-- </keep-alive> -->

		<transition-group name="notifications" class="notifications" tag="div">
			<ui-notification v-for="notification in app.notifications" :key="notification.id" :notification="notification" />
		</transition-group>

		<language-screen v-if="!language" />

		<transition name="alert-confirm">
			<ui-alert-confirm v-if="app.alert_confirm" :alert_confirm="app.alert_confirm" />
		</transition>

		<transition name="loading-screen">
			<loading-screen v-if="app.main_loader || loading" />
		</transition>

		<!-- <div id="fps">{{ average_fps }}</div> -->

		<div v-if="bad_format" class="height-error-message">
			<div class="content">
				{{ $t('not_supported_screen_size') }}
			</div>
		</div>

		<!-- <performance-detection /> -->

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { start_display_fps } from '@/js/utils/performance'
import { LANGUAGE } from '@/js/factory/language'
import UiNotification from '@/components/ui/ui-notification'
import UiAlertConfirm from '@/components/ui/ui-alert-confirm'
import Feature from 'feature.js'
import DevHelper from '@/components/dev/dev-helper'
import PerformanceDetection from '@/components/performance/performance-detection'
import LoadingScreen from '@/components/loading-screen'
import LanguageScreen from '@/components/language-screen'
import {manageEventListeners} from "@/js/managers/manageEventListeners"

let route_loaded_start = null

export default {
	name: 'App',
	data() {
		return {
			loading: true,
			loader_logo_visible: true,
			width: false,
			height: false,
			manageEventListeners: manageEventListeners()
			// average_fps: 0
		}
	},
	computed: {
		...mapGetters([ 'app', 'getGoToRouteData', 'game' ]),
		device() {
			// if (Feature.touch) return 'mobile'
			if (window.innerWidth < 663) return 'mobile'
			return 'desktop'
		},
		touch_class() {
			if (Feature.touch) return 'touch'
			else return 'not-touch'
		},
		bad_format() {
			if (this.width && this.height) {
				return this.height < 500 && this.width > this.height * 1.5
			}
			return false
		},
		language() {
			return LANGUAGE.getData({ definition: 'Language' })
		}
	},
	watch: {
		$route(newValue, oldValue) {
			if (newValue && newValue.meta && newValue.meta.loadingScreen && oldValue && oldValue.meta && oldValue.meta.loadingScreen) {
				this.loading = true
				route_loaded_start = Date.now()
			}
		},
		'game.current.game'(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.loading = true
				setTimeout(() => {
					this.$store.dispatch('set_main_loader', { main_loader: false })
					// route_loaded_start = Date.now()
				}, 750)
			}
		}
	},
	methods: {
		route_loaded() {
			let delay = 0
			if (route_loaded_start && Date.now() - route_loaded_start < 750) delay += 750

			setTimeout(() => {
				this.loading = false
				if (this.app.main_loader) this.$store.dispatch('set_main_loader', { main_loader: false })
			}, delay)
		},
		resize() {
			let main = this.$el
			if (main) {
				let device = 'mobile'
				if (main.clientWidth > 1550) device = 'desktop'
				else if (main.clientWidth > 800) device = 'tablet'

				this.height = main.clientHeight
				this.width = main.clientWidth

				if (device != this.app.device) this.$store.dispatch('set_device', { device: device })
			}
		},
	},
	mounted() {
		this.$store.dispatch('set_main_loader', { main_loader: true })
		this.$medias.init({ loadPrefetch: false }).then(async () => {
			await this.$store.dispatch('init_app')

			this.$nextTick(() => {
				this.$store.dispatch('set_main_loader', { main_loader: false })
			})
		})
		this.manageEventListeners.add(window, 'resize', this.resize)
		this.resize()
	},
	components: { DevHelper, UiNotification, PerformanceDetection, LoadingScreen, LanguageScreen, UiAlertConfirm },
	beforeDestroy() {
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus">
@import './assets/css/variables'
@import './assets/css/reset'
@import './assets/css/vuebar'

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap')

body
	font-family 'Montserrat', sans-serif
	color dark
	font-weight 500
	background-color #222

#app
	font-size 1.6rem

#app.mobile
	font-size 1.4rem

// #app
// 	&.dev-helper
// 		#main
// 			margin-left 230px
		// &.mobile
		// 	#main
		// 		.view
		// 			position absolute
		// 			left 50%
		// 			top 50%
		// 			width 384px
		// 			height 737px
		// 			transform translate(-50%, -50%)
		// 			box-shadow 0 0 0 4px #191919
		// 			overflow hidden


.notifications
	position fixed
	right 16px
	top 16px
	z-index 5000

#fps
	position absolute
	left calc(50% - 24px)
	top 0
	height 32px
	width 48px
	background-color #000
	z-index 300000
	color #fff
	font-size 1.2rem
	line-height @height
	text-align center

.height-error-message
	position fixed
	left 0
	top 0
	right 0
	bottom 0
	background-color dark
	z-index 400000
	.content
		position absolute
		left 20%
		right 20%
		top 20%
		bottom 20%
		padding 32px
		background-color #fff
		display flex
		flex-direction column
		justify-content center
		align-items center
		font-weight 700
		text-transform uppercase
		font-size 1.4rem
		box-shadow 0 0 0 8px #000
		color #f44336
		text-align center
		img
			height 64px
			width 64px
			margin 0 0 16px 0


</style>
