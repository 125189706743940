<template>
	<div class="ui-button" :class="[ type, color, width, { inactive: inactive } ]">
		<div class="button">
			<slot />
			<div v-show="loading != null" class="loader" :class="{ loading: loading, end: is_loading_end }">
				<div class="progress"></div>
				<div class="spinner"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ui-button',
	data() {
		return {
			is_loading_end: false
		}
	},
	props: {
		type: { default: null },
		color: { default: null },
		inactive: { default: null },
		width: { default: null },
		loading: { default: null }
	},
	watch: {
		loading(newValue, oldValue) {
			if (this.loading != null) {
				if (!this.loading) {
					this.is_loading_end = true
					setTimeout(() => {
						this.is_loading_end = false
					}, 500)
				}
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.mobile
	.ui-button.bounce
		&:before, &:after
			display none

.ui-button
	display inline-block
	border-radius 80px
	user-select none
	&.small
		.button
			padding 0 16px
	&.login
		.button
			border-radius 10px
			.loader
				border-radius 10px
	&.confirm
		.button
			background-color #61789E
			&:active
				background-color darken(#61789E, 5%)
			.loader.loading
				background-color #61789E
	&.back
		.button
			background-color #E96161
			&:active
				background-color darken(#E96161, 5%)
			.loader.loading
				background-color #E96161
	&.next
		.button
			background-color #FFB524
			&:active
				background-color darken(#FFB524, 5%)
			.loader.loading
				background-color #FFB524
	&.red
		.button
			background-color red
			&:active
				background-color darken(red, 5%)
			.loader.loading
				background-color red
	&.white
		.button
			background-color #fff
			color dark
			&:active
				background-color darken(#fff, 5%)
			.loader.loading
				background-color #fff
	&.inactive
		.button
			background-color dark_light
			opacity 0.4
			cursor not-allowed
			&:active
				transform none
			*
				cursor not-allowed
	&.bounce
		&:before, &:after
			content ''
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			border-radius 50%
			cursor pointer
			pointer-events none
			box-shadow 0 0 0 2px alpha(#222, 50%)
			animation bounce_button 1s infinite linear
		&:before
			animation-delay 0s
		&:after
			animation-delay 0.25s
	&.inactive.bounce
		&:before, &:after
			display none
	.button
		display flex
		align-items center
		justify-content center
		height 40px
		padding 0 32px
		background-color dark_light
		border none
		cursor pointer
		border-radius 40px
		font inherit
		line-height @height
		color #fff
		white-space nowrap
		// font-weight 700
		&:active
			transform translateY(1px)
		*
			cursor pointer
		.loader
			position absolute
			left 0
			top 0
			right 0
			bottom 0
			overflow hidden
			border-radius 80px
			mask-image radial-gradient(white, black)
			&.loading
				background-color dark_light
				// border-radius 80px
				// overflow hidden
				.progress
					transition transform 2s easeOutQuart
					opacity 1
					transform translate(-10%)
				.spinner
					opacity 1
			&.end
				.progress
					transition 0.5s easeOutQuart
					opacity 0
					transform translate(0)
			.progress
				position absolute
				left 0
				top 0
				bottom 0
				background-color darken(dark_light, 20%)
				background-color alpha(#fff, 20%)
				width 100%
				transform translate(-100%)
			.spinner
				position absolute
				left 0
				top 0
				right 0
				bottom 0
				background url(../../assets/img/button-loader.svg) center center no-repeat
				background-size auto 8px
				opacity 0


@keyframes bounce_button {
	0%, 100% {
		transform scale(1)
		opacity 1
	}
	100% {
		transform scale(1.5)
		opacity 0
	}
}

</style>
