<template>
	<transition name="performance-detection">
		<div v-if="mounted" v-show="visible" class="performance-detection">
			<div class="bg"></div>
			<div class="content">
				<p class="title">{{ $t('recommandation') }}</p>
				<p>{{ $t('quality_reduced') }}</p>

				<div class="item">
					<p class="label">{{ $t('animation_quality') }}</p>
					<div class="tabs">
						<div v-for="q in qualities" class="tab" :class="{ active: quality && quality && q == quality }" v-hammer:tap="()=> set_quality(q)">{{ q.label }}</div>
					</div>
				</div>

				<div class="buttons">
					<ui-button color="red" v-hammer:tap="()=> validate()">{{ $t('continue') }}</ui-button>
				</div>

				<div class="fps">FPS : {{ average_fps }}</div>
			</div>

			<transition name="opacity">
				<div v-if="detection_state != 'ended'" class="perf-loading">
					<div class="loading">
						<div class="loading-bar">
							<div class="progress" :class="detection_state"></div>
						</div>
						<p>{{ $t('performance_detection') }}</p>
					</div>
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { start_display_fps, stop_display_fps } from '@/js/utils/performance'

export default {
	name: 'performance-detection',
	data() {
		return {
			mounted: true,
			visible: true,
			quality: false,
			qualities: [
				{ value: 'low', label: "Standard" },
				{ value: 'medium', label: "High" },
				{ value: 'high', label: "Ultra" }
			],
			fps: 0,
			average_fps: 0,

			detection_state: 'idle'
		}
	},
	computed: {
		...mapGetters([ 'app' ]),
		saved_performance() {
			let perf = localStorage.getItem('performance')
			if (perf && ['low', 'medium', 'high'].includes(perf)) return perf
			return false
		}
	},
	methods: {
		start_detection() {
			this.visible = true
			setTimeout(() => {
				start_display_fps(this)
				setTimeout(() => {
					if (this.average_fps > 29) {
						this.set_quality(this.qualities[2])
						this.validate()
					}
					else if (this.average_fps <= 29 && this.average_fps >= 20)
						this.set_quality(this.qualities[1])
					else if (this.average_fps < 20)
						this.set_quality(this.qualities[0])

					this.detection_state = 'ended'
				}, 5000)
				this.detection_state = 'started'
			}, 1000)
		},
		end_detection() {
			stop_display_fps()
		},
		set_quality(quality) {
			this.quality = quality
		},
		validate() {
			if (this.quality) {
				localStorage.setItem('performance', this.quality.value)
				this.$store.dispatch('set_performance', { performance: this.quality.value })
				this.mounted = this.visible = false
				this.end_detection()
			}
		}
	},
	mounted() {
		if (this.saved_performance) this.mounted = false
		else this.start_detection()
	},
	beforeDestroy() {
		this.end_detection()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.performance-detection
	&.performance-detection-enter-active, &.performance-detection-leave-active
		transition 0.25s easeOutQuart
		.content
			transition 0.25s easeOutQuart
	&.performance-detection-enter, &.performance-detection-leave-to
		opacity 0
		.content
			transform translateY(-40px)



.performance-detection
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 80%)
	.content
		width calc(100% - 20px)
		// height calc(100% - 40px)
		max-width 420px
		// max-height 350px
		padding 32px 24px
		background-color #fff
		border-radius 8px
		box-shadow 0px 10px 6px alpha(#000, 30%)
		.title
			display block
			margin 0 0 8px 0
			font-size 2.4rem
			font-weight 700
		.item
			background-color #eee
			padding 16px 24px
			margin 16px -24px
			.label
				display block
				height 20px
				margin 0 0 4px 0
				text-transform uppercase
				font-size 1.2rem
				line-height 16px
				white-space nowrap
				color dark_light
			.tabs
				display flex
				justify-content center
				align-items center
				height 32px
				box-shadow inset 0 0 0 2px red
				border-radius 32px
				overflow hidden
				mask-image radial-gradient(white, black)
				.tab
					display inline-block
					width 33.3333%
					height 32px
					box-shadow inset 2px 0 0 0px red
					text-align center
					line-height @height
					cursor pointer
					&.active
						background-color red
						color #fff
		.buttons
			text-align center
			>>> .ui-button
				font-weight 500
				text-transform uppercase
				font-size 1.6rem
		.fps
			position absolute
			right 8px
			bottom 8px
			color #ccc
			font-size 1rem
	.perf-loading
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background dark
		display flex
		flex-wrap wrap
		align-items center
		justify-content center
		align-content center
		&.opacity-enter-active, &.opacity-leave-active
			transition 0.25s easeOutQuart
		&.opacity-enter, &.opacity-leave-to
			opacity 0
		> img
			width 40px
			height 40px
			opacity 0.01
		.loading
			position absolute
			left 50%
			top 50%
			transform translate(-50%, -50%)
			width 220px
			display flex
			flex-direction column
			align-items center
			justify-content center
			align-content center
			text-align center
			color #fff
			font-size 1.2rem
			font-weight 500
			text-transform uppercase
			.loading-bar
				display block
				height 8px
				width 100%
				margin 0 0 16px 0
				background-color darken(dark, 25%)
				border-radius 4px
				overflow hidden
				.progress
					float left
					height 100%
					width 0
					background-color yellow
					transition width 5s ease
					&.started, &.ended
						width 100%
	#perf
		position absolute
		left 0
		top 0
		height 32px
		padding 0 8px
		background-color #000
		z-index 300000
		color #fff
		font-size 1.2rem
		line-height @height
		text-align left
		white-space nowrap


</style>
