let last_frames = []
let n = 0
let timer = false

export const start_display_fps = (self) => {
	let iterations = 0
	timer = setInterval(() => {
		self.fps = iterations * 4
		last_frames[n] = iterations * 4
		n++
		if (n > 20) n = 0
		self.average_fps = Math.round(last_frames.reduce((a,b) => a + b, 0) / last_frames.length)
		iterations = 0
	}, 250)
	const refreshLoop = () => {
		window.requestAnimationFrame(() => {
			iterations += 1
			refreshLoop()
		})
	}
	refreshLoop()
}

export const stop_display_fps = () => {
	clearInterval(timer)
}