import Vue from 'vue'
import { cloneDeep } from 'lodash'
import Apollo from '@/apollo/apollo-client'
import { setModuleFiltersWith } from './common/functions'

// === STATE =====================================================================================
const default_state = {
	defaultFilters: {},
	modules: []
}

const state = cloneDeep(default_state)

// === GETTERS =====================================================================================
const getters = {
	module: function(state) { return state },
	filters: state => state.filters
}

// === ACTIONS =====================================================================================
const actions = {
	async get_module_filtered_context({ state, commit, dispatch }, payload = {}) {
		const filters = state.filters.with
		const variables = { filters }
		const modules = await Apollo.query('CLIENT_MODULE_FILTERED_CONTEXT', variables)
		if (modules && modules.data) {
			commit('EDIT_MODULE_STATE', { modules: modules.data })
		}
	},
	set_module_default_filters({ state, commit }, { property = null, value = null}) {
		if (!property) return
		commit('SET_MODULE_DEFAULT_FILTERS', { property, value })
	},
	set_module_filters_with({ state, commit }, { values = [], needed = [] }) {
		commit('SET_MODULE_FILTERS_WITH', { values, needed })
	},
	async set_module_filters({ commit, state, dispatch, rootGetters }, { targetModel = null, tags = null, property = null }) {
		if (!targetModel) return null
		const filters = await Apollo.query('CLIENT_FILTER_BY', tags ? { targetModel, tags } : { targetModel })
		commit('SET_MODULE_FILTERS', { filters, property })
	}
}



// === MUTATIONS =====================================================================================
const mutations = {
	SET_MODULE_DEFAULT_FILTERS(state, { property, value }) {
		state.defaultFilters[property.replace(/\s/g, '-')] = value
	},
	SET_MODULE_FILTERS_WITH(state, { values = [], needed = [] }) {
		setModuleFiltersWith({ state, values, needed })
	},
	SET_MODULE_FILTERS(state, { filters, property = 'all' }) {
		state.filters = Object.assign({}, state.filters, {
			[property]: filters
		})
		state.filters.default = state.filters.all.filter(x => x.tags.includes('default'))
	},
	EDIT_MODULE_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	},
}

export default { state, getters, actions, mutations }
