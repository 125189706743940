import Vue from 'vue'

const resetFilters = {
	practice_field_include: '',
	estimated_difficulty_equal: 0,
	gender_equal: 'no-preferences',
	age_greater_than: 0,
	age_less_than: 100,
	branded: false,
	accredited_game_equal: false,
}

const resetSearch = {
	module_name_includce: '',
	scenario_name_include: '',
	patient_name_include: '',
	certificate_id_equal: '',
	brand_name_equal: ''
}

const resetNeededFilters = {
	practice_field_include: false,
	estimated_difficulty_equal: false,
	gender_equal: false,
	age_greater_than: false,
	age_less_than: false,
	branded: false,
	accredited_game_equal: true
}

const resetNeededSearch = {
	module_name_include: false,
	scenario_name_include: false,
	patient_name_include: false,
	certificate_id_equal: false,
	brand_name_equal: false
}

// === STATE =====================================================================================
let state = {
	selected_game: false,
	patients_visible: false,
	accredited_game_equal: true,
	neededFilters: {
		...resetNeededFilters
	},
	neededSearch: {
		...resetNeededSearch
	},
	filters: {
		...resetFilters
	},
	search: {
		...resetSearch
	}
}

// === GETTERS =====================================================================================
const getters = {
	patients: function(state) { return state },
}

// === ACTIONS =====================================================================================
const actions = {
	set_patients_search_needed({ commit }, { property, value }) {
		commit('SET_PATIENTS_SEARCH_NEEDED', { property, value })
	},
	// set_accredited({ commit }, data) {
	// 	commit('EDIT_PATIENTS_STATE', { accredited_game_equal: data.accredited })
	// },
	set_patients_visible({ commit }, data) {
		commit('EDIT_PATIENTS_STATE', { patients_visible: data.patients_visible, selected_game: false })
	},
	set_selected_game({ commit }, data) {
		commit('EDIT_PATIENTS_STATE', { selected_game: data.selected_game })
	},
}

// === MUTATIONS =====================================================================================
const mutations = {
	RESET_PATIENTS_SEARCH(state) {
		state.search = {
			...resetSearch
		},
		state.neededSearch = {
			...resetNeededSearch
		}
	},
	RESET_PATIENTS_FILTER(state) {
		state.filters = {
			...resetFilters
		}
		state.neededFilters = {
			...resetNeededFilters
		}
	},
	TOGGLE_PATIENTS_FILTER_PROPERTY(state, { property = null }) {
		if (!property) return
		state.filters[property] = !state.filters[property]
	},
	SET_PATIENTS_FILTER_NEEDED(state, { property = null, value = null }) {
		if (!property) return
		state.neededFilters[property] = value
	},
	SET_PATIENTS_FILTER_PROPERTY(state, { property = null, value = null }) {
		if (!property) return
		state.filters[property] = value
	},
	SET_PATIENTS_SEARCH_NEEDED(state, { property = null, value = null }) {
		if (!property) return
		state.neededSearch[property] = !!value
	},
	SET_PATIENTS_SEARCH_PROPERTY(state, { property = null, value = null }) {
		if (!property) return
		state.search[property] = value
	},
	EDIT_PATIENTS_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	}
}

export default { state, getters, actions, mutations }
