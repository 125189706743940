import store from '@/store/store'
import Hammer from 'hammerjs'

const AlertConfirm = {}

AlertConfirm.install = function (Vue, options) {

	Vue.directive('alert-confirm', {

		inserted (el, binding, vnode, oldVnode) {
			const data = binding.value

			el.mc = new Hammer.Manager(el)
			el.mc.add( new Hammer.Tap() )

			el.mc.on("tap", (e) => {
				store.commit('EDIT_APP_STATE', { alert_confirm: data })
			})
		}

	})

}

export default AlertConfirm