import Vue from 'vue'
import Apollo from '@/apollo/apollo-client'
import { getValueOnPath } from '@/js/utils/utils'



// === STATE =====================================================================================
const state = {
	steps: [],
	last_request: {
		name: '',
		duration: 0
	}
}








// === GETTERS =====================================================================================
const getters = {
	dev: function(state) { return state },
}







// === ACTIONS =====================================================================================
const actions = {


	dev_load_steps: async function({ dispatch, state, commit }, data) {
		const result = await Apollo.query('CLIENT_GAME', { gameId: data.game_id })
		let steps = getValueOnPath(result, 'steps.data')
		commit('EDIT_DEV_STATE', { steps })

		commit('EDIT_GAME_CURRENT', { game: result })
		let step_id = result.steps.data[0].id
		dispatch('dev_set_step', { game_id: data.game_id, step_id })
	},


	dev_set_step: async function({ dispatch, state, commit }, data) {
		const result = await Apollo.query('CLIENT_GAME_STEP', { gameId: data.game_id, stepId: data.step_id })
		dispatch('set_current_step', { step: result.content })
	}
	


}



// === MUTATIONS =====================================================================================
const mutations = {

	EDIT_DEV_STATE(state, data) {
		for (var i in data) {
			Vue.set(state, i, data[i])
		}
	}

}

export default { state, getters, actions, mutations }
