import Vue from 'vue'

export const setModuleFiltersWith = ({ state = {}, values = [], needed = [] }) => {
	if (!state.filters || !state.filters.all) return
	const realNeeded = needed.reduce((a, c) => Object.assign(a, c), {})
	const getFilter = ({ filterId, value, action }) => {
		let result
		switch (action) {
			case 'between':
				if (
					value.length
					&& value.length === 2
				) {
					result = [
						stateFilters.map(x => x.action === 'greater_than'),
						stateFilters.map(x => x.action === 'less_than'),
					].map((filter, index) => {
						const { id: filterId } = filter
						return {
							filterId,
							with: JSON.stringify({ value: value[index] })
						}
					})
				}
				break
			default:
				result = [{
					filterId,
					with: JSON.stringify({ value })
				}]
				break
		}
		return result
	}
	const stateFilters = state.filters.all.slice(0)
	const defaultFilterKeys = state.filters.default.map(x => x.slug)
	const valuesFlat = values.flat().reduce((a, c) => Object.assign(a, c), {})
	const neededFilters = Object.keys(realNeeded)
		.reduce((a, key) => realNeeded[key] === true
			? Object.assign(a, {
					[key]: valuesFlat[key]
				})
			: a, {})
	state.filters.with = defaultFilterKeys.reduce((a, key) => {
		const found = state.defaultFilters.hasOwnProperty(key)
		if (found) {
			const realFilter = stateFilters.find(x => x.slug === key)
			if (realFilter) {
				const { id: filterId, action } = realFilter
				const value = state.defaultFilters[key]
				return [...a, ...getFilter({
					filterId,
					value,
					action
				})]
			}
		}
		return a
	}, [])
	state.filters.with = Object.keys(neededFilters)
		.reduce((a, key) => {
			const realFilter = stateFilters.find(x => x.slug === key)
			if (realFilter) {
				const { id: filterId, action } = realFilter
				const value = valuesFlat[key]
				return [...a, ...getFilter({
					filterId,
					value,
					action
				})]
			}
			return a
		}, state.filters.with)
	Vue.set(state.filters, 'changed', true)
}
