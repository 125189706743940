import Vue from 'vue'
import Vuebar from '@/js/vuebar'
import { debounce } from '@/js/utils/utils.js'

Vue.use(Vuebar)

const Scrollbar = {}

const is_mac = function() {
	return window.navigator.platform == 'MacIntel'
}

const update_scrollbar = function(parent) {
	let target = parent.firstChild
	target.setAttribute('data-scroll', 0)
	target.setAttribute('data-y', 0)
	target.style.transform = ''

	let helper = parent.querySelector(':scope > .scroll-helper')
	helper.style.top = 0

	Vue.nextTick(() => {
		let percent = parent.clientHeight * 100 / target.clientHeight
		if (percent >= 100) {
			percent = 100
			helper.style.display = 'none'
		} else {
			helper.style.height = percent + '%'
			helper.style.display = 'block'
		}
	})
}

const addScrollArrows = element => {
	const arrowsContainer = document.createElement('div')
	const arrowUp = document.createElement('img')
	const arrowDown = document.createElement('img')
	arrowUp.src = require('@/assets/img/scroll/scroll-arrow-up.svg')
	arrowDown.src = require('@/assets/img/scroll/scroll-arrow-down.svg')
	arrowsContainer.setAttribute('class', 'scroll-arrows-container')
	arrowUp.setAttribute('class', 'scroll-arrows scroll-arrow-up')
	arrowDown.setAttribute('class', 'scroll-arrows scroll-arrow-down')
	arrowsContainer.appendChild(arrowUp)
	arrowsContainer.appendChild(arrowDown)
	element.appendChild(arrowsContainer)
}

const arrowHide = element => element.className = String(element.classList)
	.replace(/\sscroll-arrow-show/g, '')
	.concat(' scroll-arrow-hide')
const arrowShow = element => element.className = String(element.classList)
	.replace(/\sscroll-arrow-hide/g, '')
	.concat(' scroll-arrow-show')

const scrollArrowsManager = function({ target }) {
	const {
		clientHeight,
		scrollHeight,
		scrollTop
	} = target
	const top = scrollTop === 0
	const bottom = clientHeight === scrollHeight - scrollTop
	const arrowsContainer = target.parentElement.children[1]
	const arrowUp = arrowsContainer.children[0]
	const arrowDown = arrowsContainer.children[1]
	if (top) arrowHide(arrowUp)
	else arrowShow(arrowUp)
	if (bottom) arrowHide(arrowDown)
	else arrowShow(arrowDown)
}

Scrollbar.install = function (Vue, options) {
	let lastColor = ''
	Vue.directive('scrollbar', {
		unbind(el, binding, vnode, oldVnode) {
			const child = el.childNodes[0]
			child.removeEventListener('scroll', child.scrollArrowsManager)
		},
		bind (el, binding, vnode, oldVnode) {
			addScrollArrows(el)
			const child = el.childNodes[0]
			if (binding.arg === 'scrollArrows' && !child.scrollArrowsManager) {
				child.scrollArrowsManager = debounce(scrollArrowsManager, 100)
				child.scrollArrowsManager({ target: child })
			}
			child.addEventListener('scroll', child.scrollArrowsManager)
			if (binding.value && binding.value.maxHeight) {
				Vue.nextTick(() => {
					if (el.clientHeight > binding.value.maxHeight) el.style.height = binding.value.maxHeight + 'px'
				})
			}
			if (binding.value && binding.value.backgroundColor) {
				child.classList.add(lastColor = binding.value.backgroundColor)
			}
			// Desktop
			// if (!Feature.touch && !is_mac()) {
				Vue.prototype.$vuebar.initScrollbar(el, {
					scrollThrottle: 10
				})
			// }
		},
		update(el, binding) {
			const child = el.childNodes[0]
			if (child.scrollArrowsManager) {
				child.scrollArrowsManager({ target: child })
			}
			if (binding.value && binding.value.backgroundColor) {
				child.classList.remove(lastColor)
				child.classList.add(lastColor = binding.value.backgroundColor)
			}
		}
	})
}

export default Scrollbar
