import { extend } from 'vee-validate'
import { alpha_dash, alpha_num, alpha_spaces, confirmed, email, required } from 'vee-validate/dist/rules'
import Vue from 'vue'

export const extendVeeValidate = () => {
	extend('is_true', {
		validate: value => value === true,
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.is_true`)}`
	})
	
	extend('alpha_dash', {
		...alpha_dash,
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.alpha_dash`)}`
	})
	
	extend('alpha_num', {
		...alpha_num,
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.alpha_num`)}`
	})
	
	extend('required', {
		...required,
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.required`)}`
	})
	
	extend('confirmed', {
		...confirmed,
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.confirmed`)}`
	})
	
	extend('alpha_spaces', {
		...alpha_spaces,
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.alpha`)}`
	})
	
	extend('email', {
		...email,
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.invalid`)}`
	})
	
	extend('min', {
		validate: (value, { min }) => value.length >= parseInt(min),
		params: ['min'],
		message: (fieldName, { min }) => `${Vue.i18n.translate(`vee-validate.${fieldName}.length-small`, { min })}`
	})
	
	extend('max', {
		validate: (value, { max }) => value.length <= parseInt(max),
		params: ['max'],
		message: (fieldName, { max }) => `${Vue.i18n.translate(`vee-validate.${fieldName}.length-big`, { max })}`
	})
	
	extend('strongPassword', {
		// validate: (value) => new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})").test(value),
		validate: (value) => new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\s\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\_\`\{\|\}\~])(?=.{8,})").test(value),
		message: (fieldName) => `${Vue.i18n.translate(`vee-validate.${fieldName}.notStrong`)}`
	})
}
