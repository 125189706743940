import Vue from 'vue'

export const client = {
	state: {},
	mutations: {
		clientSet(state, { property = '', value = null }) {
			if (property) Vue.set(state, property, value)
			else state.data = value
		},
	},
	actions: {
		clientSet(context, payload) {
			const {commit} = context
			commit('clientSet', payload)
		},
	},
	getters: {}
}
