import lottie from 'lottie-web'

export const manageLottie = {
	animations: [],
	create({
		container,
		path,
		renderer,
		loop,
		autoplay,
		rendererSettings,
	}) {
		const animation = lottie.loadAnimation({
			container,
			path,
			renderer,
			loop,
			autoplay,
			rendererSettings,
		})
		this.animations.push(animation)
		return animation
	},
	destroy() {
		if (!this.animations.length) return
		let index = 0
		while (index < this.animations.length) {
			const inDOM = document.body.contains(this.animations[index].wrapper)
			if (!inDOM) {
				let anim = this.animations[index]
				if (anim) {
					if (anim?.stop) anim.stop()
					if (
						anim.isLoaded
						&& anim.destroy
					) {
						anim.destroy()
						this.animations[index] = null
					}
				}
				this.animations.splice(index, 1)
			} else {
				index += 1
			}
		}
	}
}
